import { track } from "cp-analytics";

export function notifyAnalytics(eventName, dataObject = {}) {
  let user = window.loggedInUser;
  if (!user) {
    return;
  }
  const productName = "transcripts";
  const featureName = "transcripts";
  const user_id = user.id;
  const tenant_id = user.tenant_id;
  const dataObj = { user_id: user_id, tenant_id: tenant_id, ...dataObject };

  track(productName, featureName, eventName, dataObj);
}
