import React, { useEffect, useState } from "react";
import { Scoped, k } from "kremling";
import { CpIcon, CpCard } from "canopy-styleguide!sofe";
import sanitizeHtml from "sanitize-html";
import { filter } from "rxjs/operators";

import { requestMessageCenterMessages } from "../../resources/transcripts.resource";

export const MessageBanners = () => {
  const [bannerMessages, setCurrentMessages] = useState([]);
  const [hiddenBanners, setHiddenBanners] = useState([]);

  useEffect(() => {
    const hidden = localStorage.getItem("hiddenMessageBanners") || "[]";

    setHiddenBanners(JSON.parse(hidden));
  }, []);

  useEffect(() => {
    const subscription = requestMessageCenterMessages()
      .pipe(filter((m) => !m.deleted_at))
      .subscribe((messages) => setCurrentMessages(messages));

    return () => subscription.unsubscribe?.();
  }, []);

  return bannerMessages.map((message) => (
    <MessageBanner
      key={message.id}
      messageBanner={message}
      bannerMessages={bannerMessages}
      hiddenBanners={hiddenBanners}
      setHiddenBanners={setHiddenBanners}
    />
  ));
};

const MessageBanner = ({
  messageBanner,
  hiddenBanners,
  bannerMessages,
  setHiddenBanners,
}) => {
  const hidden = hiddenBanners.includes(messageBanner.id);
  const hideBanner = (id) => {
    // This filters out old deleted/expired message Ids from local storage
    const activeBannerIds = bannerMessages.map((banner) => banner.id);
    const filteredBanners = hiddenBanners.filter((b) =>
      activeBannerIds.includes(b)
    );
    const newHiddenBanners = [...filteredBanners, id];

    // sets state and local storage with new hidden message array, needs to be stringified or its not stored as an array
    setHiddenBanners(newHiddenBanners);
    localStorage.setItem(
      "hiddenMessageBanners",
      JSON.stringify(newHiddenBanners)
    );
  };

  const markup =
    messageBanner && messageBanner.text
      ? sanitizeHtml(messageBanner.text, {
          allowedTags: [
            "h3",
            "h4",
            "h5",
            "h6",
            "blockquote",
            "p",
            "ul",
            "ol",
            "nl",
            "li",
            "b",
            "i",
            "strong",
            "em",
            "strike",
            "hr",
            "br",
            "div",
            "table",
            "thead",
            "caption",
            "tbody",
            "tr",
            "th",
            "td",
          ],
          allowedAttributes: {
            "*": ["border", "height", "width", "align", "name"],
          },
        })
      : null;

  return !hidden ? (
    <Scoped css={css}>
      <CpCard className="banner">
        <div className="cp-mr-16">
          <CpIcon name="misc-bell" fill="var(--cp-color-app-warning-text)" />
        </div>
        <div className="message" dangerouslySetInnerHTML={{ __html: markup }} />
        <div style={{ flex: 1 }} />
        <div className="closer" onClick={() => hideBanner(messageBanner.id)}>
          <CpIcon name="close-large" />
        </div>
      </CpCard>
    </Scoped>
  ) : null;
};

const css = k`
  .message-center-header {
    margin: 0;
  }

  .message-center-wrapper ul {
    list-style: disc;
    margin: 0;
    padding-left: 32px;
  }

  .message-center-wrapper ul li {
    margin: 16px 0;
  }

  .banner {
    padding: 1.6rem 3.2rem;
    font-weight: bold;
    display: flex;
    align-items: center;
    background-color: var(--cp-color-well-l2-bg);
  }

  .closer {
    cursor: pointer;
  }

  .message {
    padding-right: 16px;
    font-weight: normal;
  }
`;
