import React from "react";
import { CpModal, CpButton } from "canopy-styleguide!sofe";

export default function SettingsChangedModal({ show, close }) {
  return (
    <CpModal show={show} onClose={close} width={450}>
      <CpModal.Header title="Your transcripts have finished processing" />
      <CpModal.Body>
        <div>
          Future transcript requests will{" "}
          <strong>
            only include the forms that were successfully retrieved
          </strong>
          . You can review and adjust all included forms by accessing the
          transcript settings from the three-dot menu in the top right of this
          page.
        </div>
      </CpModal.Body>
      <CpModal.Footer>
        <CpButton btnType="primary" onClick={close}>
          Continue
        </CpButton>
      </CpModal.Footer>
    </CpModal>
  );
}
