import React, { useState } from "react";
import {
  CpSelectSingle,
  CprDatepicker,
  CpIcon,
  CpTooltip,
} from "canopy-styleguide!sofe";

import {
  scheduleCadenceOptions,
  createCronString,
  cadences,
  isExpiredDate,
  fromJSDateToISO,
  getISODateNow,
  compareDates,
} from "./settings.helper";

export default function PullSchedule({
  setSettingsObj,
  settings,
  settingsOnly = false,
}) {
  const [validationError, setValidationError] = useState();
  const [cadenceIssue, setCadenceIssue] = useState();
  const { starting_at, cadence, ending_at } = settings;

  const updateStartDate = (date) => {
    if (!date) return;
    let startDate = fromJSDateToISO(date);

    if (isExpiredDate(startDate)) {
      setValidationError("The start date can't be before today's date.");
      setTimeout(setValidationError, 5000);
      startDate = getISODateNow();
    } else if (validationError) {
      setValidationError();
    }

    const cron = createCronString(startDate, cadence);
    setSettingsObj({ ...settings, starting_at: startDate, cron });
    setCadenceIssue(compareDates(startDate, ending_at, cadence, settingsOnly));
  };

  const updateEndDate = (date) => {
    if (!date) {
      setSettingsObj({ ...settings, ending_at: "" });
    }

    const endDate = fromJSDateToISO(date);
    const isBeforeStart = endDate < starting_at;

    if (isExpiredDate(endDate)) {
      setValidationError("The end date can't be before today's date.");
      setTimeout(setValidationError, 5000);
      return;
    } else if (isBeforeStart) {
      setValidationError("The end date can't be before the start date.");
      setTimeout(setValidationError, 5000);
      return;
    } else if (validationError) {
      setValidationError();
    }

    setSettingsObj({ ...settings, ending_at: endDate });
    setCadenceIssue(compareDates(starting_at, endDate, cadence, settingsOnly));
  };

  const updateCadence = (cadence) => {
    if (cadence.id === cadences.NO_REPEAT) {
      setSettingsObj({ ...settings, cron: null, cadence: null });
    } else {
      const cron = createCronString(starting_at, cadence.id);
      setSettingsObj({ ...settings, cadence: cadence.id, cron });
    }
    setCadenceIssue(compareDates(starting_at, ending_at, cadence.id));
  };

  return (
    <div style={{ height: "auto" }} data-testid="pull-schedule">
      <div className="cp-flex-column cp-mt-8">
        <label>
          Repeat{" "}
          <span>
            (Schedule notifications){" "}
            <CpTooltip
              text="The IRS has restricted use of their e-services to one hour per session. As a result, your repeating transcript requests will not be automatic. We will send you a notification to sign in and send them off. "
              position="top"
            >
              <CpIcon
                name="information-circle-open-small"
                fill="var(--cp-color-app-icon)"
              />
            </CpTooltip>
          </span>
        </label>
        <CpSelectSingle
          data={scheduleCadenceOptions}
          placeholder="Does not repeat"
          onChange={updateCadence}
          triggerIsBlock
          value={
            scheduleCadenceOptions.find((opt) => opt.id === cadence) ||
            scheduleCadenceOptions[0]
          }
        />
      </div>
      {cadence && (
        <div className="cp-flex cp-mv-16">
          <div className="cp-mr-32" style={{ width: "21rem" }}>
            <label>
              Start date<span className="cp-color-app-primary"> *</span>
            </label>
            <CprDatepicker
              id="starting_at"
              date={starting_at}
              events={{ datechange: (date) => updateStartDate(date.detail) }}
              orientation="top"
            />
          </div>
          <div style={{ width: "21rem" }}>
            <label>End date (optional)</label>
            <CprDatepicker
              id="ending_at"
              date={ending_at}
              events={{ datechange: (date) => updateEndDate(date.detail) }}
              orientation="top left"
              removeDateOption
              removeDateText="Remove end date"
            />
          </div>
        </div>
      )}
      {cadence && validationError && (
        <div className="cp-color-app-error-text">{validationError}</div>
      )}
      {cadence && cadenceIssue && (
        <div className="cp-flex-center cp-mt-16">
          <span>
            <CpIcon name="information-circle-open-large" className="cp-mr-8" />
          </span>
          <span>{cadenceIssue}</span>
        </div>
      )}
      {settings.error_count > 2 && (
        <div className="cp-flex-center cp-mt-16">
          <span>
            <CpIcon name="information-circle-open-large" className="cp-mr-8" />
          </span>
          <span>
            Your scheduled request for this client was paused because there were
            multiple failed attempts. Please double check your information
            before continuing.
          </span>
        </div>
      )}
    </div>
  );
}
