import React from "react";
import { Scoped, k } from "kremling";
import { CpCheckbox, CpRadio } from "canopy-styleguide!sofe";

export default function ReportsSelector({
  selectedReports,
  allReports,
  setAllReports,
  setSelectedReports,
  reportTypeOptions,
}) {
  const removeReport = (report) =>
    setSelectedReports(selectedReports.filter((set) => set !== report));
  const addReport = (report) =>
    setSelectedReports([...selectedReports, report]);

  return (
    <Scoped css={css}>
      <div className="stf-subheader">
        Select the report(s) you would like to save
      </div>
      <div className="reports-selection">
        <CpRadio
          onChange={(value) => setAllReports(value === "all-reports")}
          value={allReports ? "all-reports" : "individual-reports"}
          name="all-reports"
        >
          <CpRadio.Item id="all-reports">All reports</CpRadio.Item>
          <CpRadio.Item id="individual-reports">
            Select individual reports
          </CpRadio.Item>
        </CpRadio>
      </div>
      {!allReports && (
        <div className="individual-reports">
          {reportTypeOptions.map((report) => {
            const checked = selectedReports.includes(report.value);

            return (
              <div key={report.value} className="report-option">
                <CpCheckbox
                  checked={checked}
                  onChange={(checked) => {
                    if (checked) {
                      addReport(report.value);
                    } else {
                      removeReport(report.value);
                    }
                  }}
                >
                  {report.label}
                </CpCheckbox>
              </div>
            );
          })}
        </div>
      )}
    </Scoped>
  );
}

const css = k`
  .stf-header {
    font-size: 24px;
    padding: 16px 16px 0px;
  }

  .stf-subheader {
    font-size: 18px;
    font-weight: normal;
  }

  .reports-selection {
    padding: 16px 0;
  }

  .individual-reports {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    padding: 0px 40px 8px;
  }

  .report-option {
    margin-bottom: 8px;
  }
`;
