import React, { useState, useEffect } from "react";
import {
  CpButton,
  CpHeader,
  CpTooltip,
  CpIcon,
  CpModal,
  CpInput,
  CpDropdown,
  CpTabs,
} from "canopy-styleguide!sofe";
import { handleError } from "src/error";
import { successToast } from "toast-service!sofe";
import { a, m, useCss, k } from "kremling";
import { useWithUserAndTenant } from "cp-client-auth!sofe";

import {
  queueTranscriptBulkRepull,
  createTestOrganization,
  updateOrgToken,
  deleteOrgToken,
} from "../resources/transcripts.resource.js";
import { notifyAnalytics } from "../resources/analytics.resource";
import IrsStatus, {
  getActiveOrgs,
  isOrgActive,
} from "../common/irs-status.component";
import { tabs } from "./general-dashboard.component";

export default function DashboardNavbar({
  selectedItems,
  hasTranscriptsPull,
  bulkRequestCb,
  setShowRequestDialog,
  transcriptsCount,
  organizations,
  pastDueCount,
  updateOrganization,
  fetchOrganizations,
  activeTab,
  setActiveTab,
  allItems = [],
  isNewCrm = false,
}) {
  const [showCreateTestOrg, setShowCreateTestOrg] = useState(false);
  const [updateTokensModal, setUpdateTokensModal] = useState(false);
  const [createTestOrgTrigger, setCreateTestOrgTrigger] = useState(false);
  const [updateTokenId, setUpdateTokenId] = useState();
  const [deleteTokenId, setDeleteTokenId] = useState();
  const [testOrgName, setTestOrgName] = useState("");
  const [user] = useWithUserAndTenant();
  const isCanopyUser =
    user?.email.includes("@canopytax") || user?.email.includes("@getcanopy");
  const scoped = useCss(css);

  useEffect(() => {
    if (!createTestOrgTrigger || !testOrgName) return;

    const subscription = createTestOrganization(testOrgName).subscribe(
      () => {
        setShowCreateTestOrg(false);
        setCreateTestOrgTrigger(false);
        setTestOrgName("");
      },
      (err) => {
        setShowCreateTestOrg(false);
        setCreateTestOrgTrigger(false);
        setTestOrgName("");
        handleError(err);
      }
    );

    return () => subscription.unsubscribe();
  }, [createTestOrgTrigger, testOrgName]);

  useEffect(() => {
    if (!updateTokenId) return;

    const subscription = updateOrgToken(updateTokenId).subscribe(
      (org) => {
        updateOrganization(org);
        setUpdateTokenId();
      },
      (err) => {
        setUpdateTokenId();
        handleError(err);
      }
    );

    return () => subscription.unsubscribe();
  }, [updateTokenId, updateOrganization]);

  useEffect(() => {
    if (!deleteTokenId) return;

    const subscription = deleteOrgToken(deleteTokenId).subscribe(
      () => {
        updateOrganization({ id: deleteTokenId, deleted_at: true });
        setDeleteTokenId();
      },
      (err) => {
        setDeleteTokenId();
        handleError(err);
      }
    );

    return () => subscription.unsubscribe();
  }, [deleteTokenId, updateOrganization]);

  const queueTranscriptPull = () => {
    if (activeTab === tabs.scheduled) {
      notifyAnalytics("transcripts.scheduled_requests_tab_manual_selection", {
        requested_amount: selectedItems?.length,
      });
    } else {
      notifyAnalytics("transcripts.bulk_request", {
        requested_amount: selectedItems?.length,
      });
    }

    const selectedClients = [];
    const mappedItems = [];

    selectedItems.forEach((id) => {
      const item = allItems.find((item) => item.transcript_id === id);
      if (isNewCrm) {
        mappedItems.push({
          client_id: item?.client_id,
          contact_id: item?.contact_id,
        });
      }
      selectedClients.push(item?.client_id);
    });

    const body = isNewCrm
      ? { client_and_contact_map: mappedItems }
      : { client_ids: selectedClients };

    return queueTranscriptBulkRepull(body).subscribe(() => {
      bulkRequestCb(selectedClients);
      successToast("Your transcripts request has been submitted.");
    }, handleError);
  };

  const limitReached =
    transcriptsCount?.successful_count >= transcriptsCount?.limit;

  return (
    <div
      {...scoped}
      className="dashboard-navbar cp-box-shadow-l1"
      style={{ height: "11.6rem" }}
    >
      <CpHeader
        border={false}
        style={{ backgroundColor: "var(--cp-color-well-l1-bg)" }}
      >
        <div className="cp-flex-center">
          <div className="cps-subheader cp-mr-8">Transcripts</div>
          {isCanopyUser && (
            <CpDropdown
              renderTrigger={({ toggle }) => (
                <CpButton
                  icon="misc-kabob"
                  onClick={toggle}
                  aria-label="IRS sign in options"
                />
              )}
              renderContent={() => (
                <div className="cp-select-list">
                  <button onClick={() => setShowCreateTestOrg(true)}>
                    Create fake org
                  </button>
                  <button onClick={() => setUpdateTokensModal(true)}>
                    Update org tokens
                  </button>
                </div>
              )}
              position="bottom-start"
            />
          )}
        </div>
        <div className="cp-flex-center">
          <IrsStatus
            organizations={organizations}
            fetchOrganizations={fetchOrganizations}
            transcriptsLimitReached={limitReached}
          />
          {hasTranscriptsPull && (
            <div className={m("flex-count", transcriptsCount)}>
              <CpButton
                btnType="primary"
                onClick={() =>
                  getActiveOrgs(organizations).length && selectedItems?.length
                    ? queueTranscriptPull()
                    : setShowRequestDialog(true)
                }
                disabled={limitReached}
              >
                Request transcripts
              </CpButton>
              {transcriptsCount && (
                <div
                  className={a("cp-mt-8").t(
                    "cp-color-app-warning-text cp-mr-8",
                    "cp-color-app-secondary-text",
                    limitReached
                  )}
                >
                  {transcriptsCount.successful_count}/{transcriptsCount.limit}{" "}
                  Transcripts Pulled
                  {!limitReached && (
                    <>
                      <CpTooltip
                        position="bottom-end"
                        interactive
                        text={
                          <>
                            Trial accounts allow for a maximum of 3 successful
                            transcripts requests. This limit is shared between
                            all team members on an account. For unlimited
                            requests,{" "}
                            <a
                              className="cp-color-app-primary-dark-text"
                              href="/#/global-settings/account-management"
                            >
                              <u>upgrade</u>
                            </a>{" "}
                            in your account management settings.
                          </>
                        }
                      >
                        <CpIcon name="information-circle-open-small" />
                      </CpTooltip>
                    </>
                  )}
                </div>
              )}
            </div>
          )}
        </div>
      </CpHeader>
      <CpTabs
        className="cp-ml-24 cp-mt-20"
        activeId={activeTab}
        onChange={(tab) => {
          if (tab === tabs.scheduled) {
            notifyAnalytics("transcripts.scheduled_requests_tab_click");
          }
          setActiveTab(tab);
        }}
      >
        <CpTabs.Button id={tabs.all}>All Transcripts</CpTabs.Button>
        <CpTabs.Button id={tabs.scheduled} notification={pastDueCount > 0}>
          Scheduled Requests
        </CpTabs.Button>
      </CpTabs>
      <CpModal
        show={showCreateTestOrg}
        onClose={() => {
          setTestOrgName("");
          setShowCreateTestOrg(false);
        }}
      >
        <CpModal.Header title="Create Test Organization" />
        <CpModal.Body>
          <CpInput
            label="Test Org Name"
            required
            value={testOrgName}
            onChange={setTestOrgName}
            message="This will be a fake organization that will request fake transcripts for internal testing purposes."
          />
        </CpModal.Body>
        <CpModal.Footer>
          <CpButton
            btnType="primary"
            onClick={() => setCreateTestOrgTrigger(true)}
            className="cp-mr-8"
            disabled={!testOrgName}
          >
            Create Organization
          </CpButton>
        </CpModal.Footer>
      </CpModal>
      <CpModal
        show={updateTokensModal}
        onClose={() => {
          setUpdateTokensModal(false);
        }}
      >
        <CpModal.Header title="Update Organization Tokens" />
        <CpModal.Body>
          <div {...scoped}>
            {organizations
              .filter((org) => org.irs_user_id === "CanopyTax")
              .map((org) => {
                const isActive = isOrgActive(org);
                return (
                  <div key={org.id} className="fake-org-item cp-ellipsis">
                    <div className="cp-flex-column">
                      <div>{org.dba}</div>
                      <div
                        className={
                          isActive
                            ? "cp-color-app-success-text"
                            : "cp-color-app-disabled-text"
                        }
                      >
                        {isActive ? "Signed in" : "Signed out"}
                      </div>
                    </div>
                    <div className="update-fake-org-btn">
                      <CpButton
                        btnType="flat"
                        onClick={() => setUpdateTokenId(org.id)}
                        className="cp-mr-8"
                        showLoader={updateTokenId === org.id}
                      >
                        Update
                      </CpButton>
                      <CpButton
                        icon="crud-trash-small"
                        aria-label="Delete org"
                        onClick={() => setDeleteTokenId(org.id)}
                        className="cp-mr-8"
                        showLoader={deleteTokenId === org.id}
                      />
                    </div>
                  </div>
                );
              })}
          </div>
        </CpModal.Body>
      </CpModal>
    </div>
  );
}

const css = k`
  .dashboard-navbar {
    position: fixed;
    top: 0;
    width: calc(100% - 8rem);
    z-index: 100;
    background: var(--cp-color-well-l1-bg);
  }

  .fake-org-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.8rem 2.4rem;
    margin-left: -16px;
    margin-right: -16px;
    width: 42rem;
  }

  .update-fake-org-btn{
    visibility: hidden;
  }

  .fake-org-item:hover {
    background-color: var(--cp-color-select-list-hover-bg);
  }

  .fake-org-item:hover .update-fake-org-btn {
    visibility: visible;
  }

  .flex-count {
    display: flex;
    flex-direction: column;
    align-items: end;
    margin-top: 3.2rem;
  }
`;
