import React from "react";
import { CpIcon } from "canopy-styleguide!sofe";
import { orderBy } from "lodash";
import { Scoped, k } from "kremling";

export default function CafForm({
  title,
  list,
  expanded,
  setExpanded,
  index,
  showMessageDetail,
}) {
  const sortedList = orderBy(list, ["year"], ["desc"]);

  return (
    <Scoped css={css}>
      <>
        <div
          data-testid="expander"
          className="caf-item"
          onClick={() => setExpanded(expanded ? null : index)}
        >
          <CpIcon name={expanded ? "caret-small-down" : "caret-small-right"} />
          {title}
        </div>
        <div className="sublist">
          {expanded && (
            <div className="table">
              <div className="table-header-columns">
                <div className="header-column form-year">Year</div>
                <div className="header-column form-type">Form type</div>
              </div>
              {sortedList.map((item) => {
                return (
                  <div
                    key={`${item.year}${item.month ? `-${item.month}` : ""}-${
                      item.subtype
                    }`}
                    data-testid="doc-row"
                    className="row"
                  >
                    <div className="form-year">{item.year}</div>
                    {item.response?.message ? (
                      <div
                        className="clickable-form"
                        onClick={() => showMessageDetail(item)}
                      >
                        {item.subtype}
                      </div>
                    ) : (
                      <div className="form-type">{item.subtype}</div>
                    )}
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </>
    </Scoped>
  );
}

const css = k`
  .caf-item {
    cursor: pointer;
    padding-right: 0.6rem;
    padding-left: 0.4rem;
    display: flex;
    align-items: center;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    position: relative;
    text-decoration: none;
    background-color: var(--cp-color-card-bg);
    transition: background-color 200ms ease;
  }

  .caf-item:hover {
    background-color: var(--cp-color-select-list-hover-bg);
  }

  .sublist {
    padding-left: 2.4rem;
  }

  .form-year {
    width: 30%;
  }

  .form-type {
    width: 70%;
  }

  .header-column {
    font-weight: bold;
  }

  .table-header-columns,
  .row {
    display: flex;
    padding-right: 0.8rem;
  }

  .clickable-form {
    width: 70%;
    color: var(--cp-color-app-primary);
    cursor: pointer;
  }
`;
