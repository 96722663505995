import React from "react";
import Cancelable from "react-disposable-decorator";
import { handleError } from "src/error";
import { cloneDeep, isEmpty, findIndex } from "lodash";
import { NavContent } from "primary-navbar!sofe";
import { Scoped, k, a } from "kremling";
import { CpLoader, CpPagination } from "canopy-styleguide!sofe";
import CpClientAuth, { UserTenantProps } from "cp-client-auth!sofe";
import { onPusher } from "fetcher!sofe";
import { filter as rxFilter, pluck, first } from "rxjs/operators";
import { forkJoin } from "rxjs";
import { DateTime } from "luxon";
import { featureEnabled } from "feature-toggles!sofe";

import DashboardNavbar from "./dashboard-navbar.component.js";
import {
  getTranscriptsList,
  getScheduledList,
  patchUserPreferences,
  getIrsOrganizations,
  getSuccessfulTranscriptsCount,
  getNoticesCount,
  getPastDueCount,
} from "src/resources/transcripts.resource.js";
import { isTranscriptsOnly } from "../e-services/common/settings.helper";

import { MessageBanners } from "../common/message-banner/message-banner.component";
import TranscriptsList from "src/dashboard/transcripts-list.component.js";
import TranscriptsScheduledList from "./transcripts-scheduled-list.component";
import { transcriptStatusTypes } from "../common/transcripts-status.helper";
import WelcomeOverlay from "./welcome-overlay.component.js";
import TranscriptsStatusDetailsModal from "../common/transcripts-status-details-modal.component";
import TranscriptRequestDialog from "../e-services/transcripts-request-dialog.component";
import TranscriptRequestSettings from "../e-services/transcripts-request-settings.component";
import TNFreeTrialUpgradeBanner from "../common/tn-upgrade-banner.component";
import { getActiveOrgs } from "src/common/irs-status.component.js";

export const tabs = {
  all: "ALL",
  scheduled: "SCHEDULED",
};
@Cancelable
@UserTenantProps({
  permissions: {
    hasTranscriptsPull: "transcripts_pull",
    hasUnlimitedPulls: "transcripts_pull_unlimited",
  },
})
export default class GeneralDashboard extends React.Component {
  state = {
    activeTab: tabs.all,
    allItems: { items: [], page: 0, lastPage: 0 },
    allSelected: false,
    connectionFetched: false,
    filterParams: {
      client_id: [],
      client_name: [],
      last_pull_date: {},
      last_successful_pull_date: {},
      status: [],
    },
    loading: true,
    noticesCount: null,
    organizations: [],
    pastDueCount: 0,
    pendingNewClients: [],
    requestDialogClientId: "",
    scheduleFilterParams: {
      client_id: [],
      cadence: {},
      next_run_date: {},
      organization: [],
    },
    scheduledItems: {
      schedules: [],
      lastPage: 0,
      page: 0,
    },
    scheduleLoading: true,
    scheduleSort: { sortColumn: null, sortDirection: null },
    selectedItems: [],
    settingsModalIds: { clientId: "", contactId: "" },
    showRequestDialog: false,
    showWelcomeOverlay: false,
    statusModalsDetails: null,
    tableSort: { sortColumn: null, sortDirection: null },
    transcriptsCount: null,
  };

  componentDidMount() {
    const storedFilterParams = localStorage.getItem("transcripts-filters");
    if (storedFilterParams) {
      const { filterParams, tableSort } = JSON.parse(storedFilterParams);
      this.setState({ tableSort, filterParams });
    }
    const storedScheduleFilterParams = localStorage.getItem(
      "transcripts-schedule-filters"
    );
    if (storedScheduleFilterParams) {
      const { scheduleFilterParams, scheduleSort } = JSON.parse(
        storedScheduleFilterParams
      );
      this.setState({ scheduleFilterParams, scheduleSort });
    }
    this.getOrgsSub = forkJoin([
      CpClientAuth.getLoggedInUserAsObservable().pipe(first()),
      getIrsOrganizations(),
      getPastDueCount(),
    ]).subscribe(([user, organizations, pastDueCount = 0]) => {
      const validOrgs = organizations.filter((org) => !org.deleted_at);
      this.setState({
        pastDueCount,
        connectionFetched: true,
        organizations: validOrgs,
      });

      if (this.props.permissions.hasTranscriptsPull) {
        if (this.shouldShowWelcomeOverlay(user)) {
          this.setState({ showWelcomeOverlay: true });
          patchUserPreferences(user.id, {
            hide_welcome_overlay: true,
          }).subscribe((user) => {
            CpClientAuth.updateLoggedInUserObservable(user);
          }, handleError);
        }
      }
      this.ispLoginPusherHandler();
    }, handleError);

    if (this.props.loggedInUser && !this.props.permissions.hasUnlimitedPulls) {
      this.fetchTranscriptsNoticesCount();
    }

    this.transcriptsPusherHandler();
  }

  componentWillUnmount() {
    this.getOrgsSub?.unsubscribe();
    this.transcriptsListSub?.unsubscribe();
    this.scheduledListSub?.unsubscribe();
    this.transcriptsPusherHandlerSub?.unsubscribe();
    this.ispLoginPusherHandlerSub?.unsubscribe();
    this.transcriptsNoticesCountSub?.unsubscribe();
    this.irsOrgSub?.unsubscribe();
    this.pastDueCountSub?.unsubscribe();
  }

  componentDidUpdate(prevProps) {
    if (
      !prevProps.loggedInUser &&
      this.props.loggedInUser &&
      !this.props.permissions.hasUnlimitedPulls
    ) {
      this.fetchTranscriptsNoticesCount();
    }
  }

  render() {
    const {
      activeTab,
      allItems,
      allSelected,
      filterParams,
      loading,
      noticesCount,
      organizations,
      pastDueCount,
      requestDialogClientId,
      scheduleFilterParams,
      scheduledItems,
      scheduleLoading,
      scheduleSort,
      selectedItems,
      settingsModalIds,
      showRequestDialog,
      showWelcomeOverlay,
      statusModalsDetails,
      tableSort,
      transcriptsCount,
    } = this.state;

    const noticesLimitReached =
      noticesCount?.successful_count >= noticesCount?.limit;
    const transcriptsLimitReached =
      transcriptsCount?.successful_count >= transcriptsCount?.limit;
    const isCrmHeirarchy =
      featureEnabled("ft_crm") &&
      this.props.tenant?.crm_status === "crm_hierarchy_complete";

    return (
      <NavContent
        hasTopnavSecondary={true}
        style={{ marginLeft: "8rem", padding: "0px" }} // {/* not inline style; props to external component */}
      >
        <Scoped css={css}>
          <DashboardNavbar
            hasTranscriptsPull={this.props.permissions.hasTranscriptsPull}
            bulkRequestCb={(itemsPulled) => {
              this.setItemsToPending(itemsPulled);
              this.deselectAllItems();
            }}
            selectedItems={selectedItems}
            setShowRequestDialog={(showRequestDialog) =>
              this.setState({ showRequestDialog })
            }
            transcriptsCount={transcriptsCount}
            organizations={organizations}
            pastDueCount={pastDueCount}
            updateOrganization={this.updateOrganization}
            fetchOrganizations={this.fetchOrganizations}
            activeTab={activeTab}
            setActiveTab={(activeTab) =>
              this.setState({
                activeTab,
                allSelected: false,
                selectedItems: [],
              })
            }
            allItems={
              activeTab !== tabs.scheduled
                ? allItems.items
                : scheduledItems.schedules
            }
            isNewCrm={isCrmHeirarchy}
          />
          <WelcomeOverlay
            show={showWelcomeOverlay}
            onClose={() => this.setState({ showWelcomeOverlay: false })}
          />
          {loading ? (
            <div className="loader-wrapper">
              <CpLoader size="lg" />
            </div>
          ) : (
            <div
              style={{
                marginTop: "11.6rem",
              }}
            >
              <MessageBanners />
              {!this.props.permissions.hasUnlimitedPulls &&
                (noticesLimitReached || transcriptsLimitReached) && (
                  <TNFreeTrialUpgradeBanner
                    transcriptsCount={transcriptsCount}
                    noticesCount={noticesCount}
                    noticesLimitReached={noticesLimitReached}
                    transcriptsLimitReached={transcriptsLimitReached}
                  />
                )}
              <div>
                {activeTab !== tabs.scheduled && (
                  <TranscriptsList
                    sortOptions={tableSort}
                    filterParams={filterParams}
                    updateFilterParams={this.updateFilterParams}
                    items={allItems.items}
                    selectedItems={selectedItems}
                    deselectItem={this.deselectItem}
                    selectItem={this.selectItem}
                    allSelected={allSelected}
                    selectAllItems={this.selectAllItems}
                    deselectAllItems={this.deselectAllItems}
                    setStatusObj={(statusModalsDetails) =>
                      this.setState({ statusModalsDetails })
                    }
                    setShowRequestDialog={(showRequestDialog, clientId = "") =>
                      this.setState({
                        showRequestDialog,
                        requestDialogClientId: clientId,
                      })
                    }
                    showSettingsModal={(settingsModalId, contactId = "") =>
                      this.setState({
                        settingsModalIds: {
                          clientId: settingsModalId,
                          contactId,
                        },
                      })
                    }
                    transcriptsCount={transcriptsCount}
                    activeOrgs={getActiveOrgs(organizations)}
                    isCrmHeirarchy={isCrmHeirarchy}
                  />
                )}
                {activeTab === tabs.scheduled && (
                  <TranscriptsScheduledList
                    activeOrgs={getActiveOrgs(organizations)}
                    allSelected={allSelected}
                    deselectItem={this.deselectItem}
                    deselectAllItems={this.deselectAllItems}
                    filterParams={scheduleFilterParams}
                    pastDueCount={pastDueCount}
                    scheduledItems={scheduledItems}
                    scheduleLoading={scheduleLoading}
                    selectAllItems={this.selectAllItems}
                    selectItem={this.selectItem}
                    selectedItems={selectedItems}
                    setShowRequestDialog={(showRequestDialog, clientId = "") =>
                      this.setState({
                        showRequestDialog,
                        requestDialogClientId: clientId,
                      })
                    }
                    showSettingsModal={(settingsModalId, contactId = "") =>
                      this.setState({
                        settingsModalIds: {
                          clientId: settingsModalId,
                          contactId,
                        },
                      })
                    }
                    sortOptions={scheduleSort}
                    transcriptsCount={transcriptsCount}
                    updateFilterParams={this.updateScheduleFilters}
                    isCrmHeirarchy={isCrmHeirarchy}
                  />
                )}
              </div>
            </div>
          )}
          <div
            className={a("paginator").m(
              "hidden-div",
              activeTab !== tabs.all || loading || !allItems?.items?.length
            )}
          >
            <CpPagination
              key="all"
              onChange={(page) => {
                this.setState(
                  (prevState) => ({
                    allItems: {
                      ...prevState.allItems,
                      page,
                    },
                  }),
                  this.fetchAllItems
                );
              }}
              lastPage={allItems.lastPage}
              initialPage={allItems.page}
              pagesToDisplay={10}
            />
          </div>
          <div
            className={a("paginator").m(
              "hidden-div",
              activeTab !== tabs.scheduled ||
                scheduleLoading ||
                !scheduledItems?.schedules?.length
            )}
          >
            <CpPagination
              key="scheduled"
              onChange={(page) => {
                this.setState(
                  (prevState) => ({
                    scheduledItems: {
                      ...prevState.scheduledItems,
                      page,
                    },
                  }),
                  this.fetchScheduledItems
                );
              }}
              lastPage={scheduledItems.lastPage}
              initialPage={scheduledItems.page}
              pagesToDisplay={10}
            />
          </div>
          <TranscriptsStatusDetailsModal
            show={!!statusModalsDetails}
            close={() => this.setState({ statusModalsDetails: null })}
            statusObj={statusModalsDetails}
            transcriptsId={statusModalsDetails?.id}
          />
          <TranscriptRequestDialog
            show={showRequestDialog}
            onClose={(clientId) => {
              if (clientId) {
                this.setState((prevState) => {
                  return {
                    pendingNewClients: [
                      ...prevState.pendingNewClients,
                      clientId,
                    ],
                  };
                });
              }

              this.setState({
                showRequestDialog: false,
                requestDialogClientId: "",
              });
            }}
            clientId={requestDialogClientId}
            transcriptsCount={transcriptsCount}
            fetchOrganizations={this.fetchOrganizations}
          />
          <TranscriptRequestSettings
            show={!!settingsModalIds.clientId}
            onClose={() => this.setState({ settingsModalIds: {} })}
            onSaveCb={(savedSettings) => this.updateRowSettings(savedSettings)}
            onDeleteCb={(clientId, undo) => {
              if (undo) {
                this.fetchAllItems();
                this.fetchScheduledItems();
              } else {
                this.removeTranscriptsRow(clientId);
              }
            }}
            clientId={settingsModalIds?.clientId}
            contactId={settingsModalIds?.contactId}
            scheduleTabActive={activeTab === tabs.scheduled}
          />
        </Scoped>
      </NavContent>
    );
  }

  setItemsToPending = (itemsToUpdate = []) => {
    const { items } = this.state;
    const transcriptsList = cloneDeep(items);

    itemsToUpdate.forEach((clientId) => {
      const transcriptIndex = findIndex(
        transcriptsList,
        (transcript) => transcript.client_id === clientId
      );
      if (transcriptIndex >= 0) {
        // update transcript row without fetching all transcripts again
        transcriptsList[transcriptIndex].category =
          transcriptStatusTypes.PENDING;
        transcriptsList[transcriptIndex].status = "Pending";
        transcriptsList[transcriptIndex].last_pull_date = DateTime.utc().toISO({
          includeOffset: false,
        });
      }
    });
    this.setState({ items: transcriptsList });
  };

  updateFilterParams = (
    column,
    sortDirection,
    filterSelections,
    persistSort
  ) => {
    const { columnType, sortName } = column;
    this.setState((prevState) => {
      let tableSort = prevState.tableSort;
      let filterParams = prevState.filterParams;
      const sortUpdated =
        sortName !== tableSort.sortColumn ||
        sortDirection !== tableSort.sortDirection;
      const filterUpdated = filterParams[columnType] !== filterSelections;

      if ((!sortUpdated || persistSort) && !filterUpdated) {
        return;
      } else if (persistSort) {
        tableSort = prevState.tableSort;
      } else if (sortUpdated && !sortDirection) {
        tableSort = { sortColumn: null, sortDirection: null };
      } else if (sortUpdated) {
        tableSort = { sortColumn: sortName, sortDirection };
      }

      if (filterUpdated) {
        filterParams = {
          ...prevState.filterParams,
          [columnType]: filterSelections,
        };
      }

      localStorage.setItem(
        "transcripts-filters",
        JSON.stringify({
          tableSort,
          filterParams,
        })
      );

      return {
        allItems: { ...prevState.allItems, page: 0 },
        tableSort,
        filterParams,
      };
    }, this.fetchAllItems);
  };

  updateScheduleFilters = (
    column,
    sortDirection,
    filterSelections,
    persistSort
  ) => {
    const { columnType, sortName } = column;
    this.setState(({ scheduleSort, scheduleFilterParams, scheduledItems }) => {
      let tableSort = scheduleSort;
      let filterParams = scheduleFilterParams;
      const sortUpdated =
        sortName !== tableSort.sortColumn ||
        sortDirection !== tableSort.sortDirection;
      const filterUpdated = filterParams[columnType] !== filterSelections;

      if ((!sortUpdated || persistSort) && !filterUpdated) {
        return;
      } else if (persistSort) {
        tableSort = scheduleSort;
      } else if (sortUpdated && !sortDirection) {
        tableSort = { sortColumn: null, sortDirection: null };
      } else if (sortUpdated) {
        tableSort = { sortColumn: sortName, sortDirection };
      }

      if (filterUpdated) {
        filterParams = {
          ...scheduleFilterParams,
          [columnType]: filterSelections,
        };
      }

      localStorage.setItem(
        "transcripts-schedule-filters",
        JSON.stringify({
          scheduleSort: tableSort,
          scheduleFilterParams: filterParams,
        })
      );

      return {
        scheduledItems: { ...scheduledItems, page: 0 },
        scheduleSort: tableSort,
        scheduleFilterParams: filterParams,
      };
    }, this.fetchScheduledItems);
  };

  fetchTranscriptsNoticesCount = () => {
    this.transcriptsNoticesCountSub = forkJoin([
      getSuccessfulTranscriptsCount(),
      getNoticesCount(),
    ]).subscribe(([transcriptsCount, noticesCount]) => {
      const noticesLimitReached =
        noticesCount?.successful_count >= noticesCount?.limit;
      const transcriptsLimitReached =
        transcriptsCount?.successful_count >= transcriptsCount?.limit;

      if (noticesLimitReached || transcriptsLimitReached) {
        window.dispatchEvent(
          new CustomEvent("cp:tn-trial-limits-reached", {
            detail: {
              notices: noticesLimitReached,
              transcripts: transcriptsLimitReached,
            },
          })
        );
      }

      this.setState({ transcriptsCount, noticesCount });
    }, handleError);
  };

  fetchOrganizations = () => {
    this.irsOrgSub = getIrsOrganizations().subscribe((organizations) => {
      const validOrgs = organizations.filter((org) => !org.deleted_at);
      this.setState({ organizations: validOrgs });
    }, handleError);
  };

  fetchAllItems = (fetchObj = {}) => {
    const { isNewClient } = fetchObj;
    const { tableSort, filterParams, allItems } = this.state;
    const limit = 50;
    const offset = limit * allItems.page;
    const clonedFilterParams = cloneDeep(filterParams);
    const emptyFilterProps = Object.keys(clonedFilterParams).filter(
      (colType) => {
        return isEmpty(clonedFilterParams[colType]);
      }
    );

    emptyFilterProps.forEach((colType) => delete clonedFilterParams[colType]);

    this.setState(
      () => ({ loading: true }),
      () => {
        this.transcriptsListSub = getTranscriptsList(limit, offset, {
          tableSort,
          filterParams: clonedFilterParams,
          isNewClient,
        }).subscribe(
          (res) => {
            this.setState((prevState) => {
              return {
                allSelected: false,
                allItems: {
                  ...res,
                  items: res.transcripts,
                  lastPage: Math.floor(res.total / limit),
                  page: prevState.allItems.page,
                },
                loading: false,
                selectedItems: [],
              };
            });
          },
          (err) => {
            this.setState({ loading: false });
            handleError(err);
          }
        );
      }
    );
  };

  fetchScheduledItems = (fetchObj = {}) => {
    const { isNewClient } = fetchObj;
    const { scheduledItems, scheduleFilterParams, scheduleSort } = this.state;
    const limit = 50;
    const offset = limit * scheduledItems.page;
    const clonedFilterParams = cloneDeep(scheduleFilterParams);
    const emptyFilterProps = Object.keys(clonedFilterParams).filter(
      (colType) => {
        return isEmpty(clonedFilterParams[colType]);
      }
    );

    emptyFilterProps.forEach((colType) => delete clonedFilterParams[colType]);

    this.setState(
      () => ({ scheduleLoading: true }),
      () => {
        this.scheduledListSub = getScheduledList(limit, offset, {
          tableSort: scheduleSort,
          filterParams: clonedFilterParams,
          isNewClient,
        }).subscribe(
          (res) => {
            this.setState({
              allSelected: false,
              scheduledItems: {
                ...res,
                lastPage: Math.floor(res.total / limit),
                page: scheduledItems.page,
              },
              scheduleLoading: false,
              selectedItems: [],
            });
          },
          (err) => {
            this.setState({ scheduleLoading: false });
            handleError(err);
          }
        );
      }
    );
  };

  fetchPastDueCount = () => {
    this.pastDueCountSub = getPastDueCount().subscribe(
      (pastDueCount) => this.setState({ pastDueCount }),
      handleError
    );
  };

  selectItem = (item) => {
    this.setState((prevState) => {
      return {
        selectedItems: [...prevState.selectedItems, item.transcript_id],
      };
    });
  };

  deselectItem = (item) => {
    this.setState((prevState) => {
      const selectedItems = prevState.selectedItems.filter(
        (id) => id !== item.transcript_id
      );
      return {
        allSelected: false,
        selectedItems,
      };
    });
  };

  selectAllItems = () => {
    this.setState((prevState) => {
      const items =
        prevState.activeTab === tabs.scheduled
          ? prevState.scheduledItems.schedules
          : prevState.allItems.items;
      const selectedItems = items.map((item) => item.transcript_id);
      return {
        allSelected: true,
        selectedItems,
      };
    });
  };

  deselectAllItems = () => {
    this.setState(() => ({
      allSelected: false,
      selectedItems: [],
    }));
  };

  removeTranscriptsRow = (clientId) => {
    const { allItems, scheduledItems } = this.state;
    // remove transcript row without fetching all transcripts again
    const itemList = allItems.items.filter(
      (transcript) => transcript.client_id !== clientId
    );
    const scheduledList = scheduledItems.schedules.filter(
      (transcript) => transcript.client_id !== clientId
    );
    this.setState((prevState) => ({
      allItems: { ...prevState.allItems, items: itemList },
      scheduledItems: { ...prevState.scheduledItems, schedules: scheduledList },
    }));
  };

  updateOrganization = (updatedOrg) => {
    this.setState((prevState) => {
      const organizations = prevState.organizations.map((org) => {
        if (org.id === updatedOrg.id) {
          return updatedOrg;
        } else {
          return org;
        }
      });
      return { organizations };
    });
  };

  updateRowSettings = (settings) => {
    const { allItems, scheduledItems, organizations } = this.state;
    const transcriptsList = cloneDeep(allItems.items);
    const scheduledList = cloneDeep(scheduledItems.schedules);

    const transcriptIndex = findIndex(
      transcriptsList,
      (transcript) => transcript.client_id === settings.client_id
    );
    const scheduledIndex = findIndex(
      scheduledList,
      (transcript) => transcript.client_id === settings.client_id
    );

    if (transcriptIndex >= 0) {
      if (settings.cadence && !transcriptsList[transcriptIndex].cadence) {
        this.fetchScheduledItems();
      }

      // update transcript settings for row without fetching all transcripts again
      transcriptsList[transcriptIndex].cadence = settings.cadence;
      transcriptsList[transcriptIndex].next_run_at = settings.next_run_at;
      transcriptsList[transcriptIndex].poa_expiration_at =
        settings.poa_expiration_at;
      transcriptsList[transcriptIndex].irs_token_id = settings.irs_token_id;
      transcriptsList[transcriptIndex].has_settings = true;
    }

    if (scheduledIndex >= 0) {
      if (!settings.cadence && scheduledList[scheduledIndex].cadence) {
        scheduledList.splice(scheduledIndex, 1);
      } else {
        // update transcript settings for row without fetching all transcripts schedules again
        scheduledList[scheduledIndex].cadence = settings.cadence;
        scheduledList[scheduledIndex].next_run_at = settings.next_run_at;
        scheduledList[scheduledIndex].has_settings = true;
        scheduledList[scheduledIndex].irs_token_id = settings.irs_token_id;
        scheduledList[scheduledIndex].dba = organizations.find(
          (org) => org.id === settings.irs_token_id
        )?.dba;
      }

      this.fetchPastDueCount();
    }

    this.setState((prevState) => ({
      allItems: { ...prevState.allItems, items: transcriptsList },
      scheduledItems: { ...prevState.scheduledItems, schedules: scheduledList },
    }));
  };

  updateTranscriptsRow = (details) => {
    const { allItems, pendingNewClients } = this.state;
    const transcriptsList = cloneDeep(allItems.items);

    let transcriptIndex = findIndex(transcriptsList, (transcript) => {
      return details.transcript_id === transcript.transcript_id;
    });
    if (transcriptIndex < 0) {
      transcriptIndex = findIndex(transcriptsList, (transcript) => {
        if (details.contact_id) {
          return (
            transcript.client_id === details.client_id &&
            transcript.contact_id === details.contact_id
          );
        } else {
          return transcript.client_id === details.client_id;
        }
      });
    }

    if (transcriptIndex >= 0) {
      // update transcript row without fetching all transcripts again
      transcriptsList[transcriptIndex].transcript_id = details.transcript_id;
      transcriptsList[transcriptIndex].category = details.category;
      transcriptsList[transcriptIndex].status = details.label;
      transcriptsList[transcriptIndex].last_pull_date = DateTime.utc().toISO({
        includeOffset: false,
      });
      transcriptsList[transcriptIndex].last_successful_pull_date =
        details.category === transcriptStatusTypes.SUCCESS
          ? DateTime.utc().toISO({ includeOffset: false })
          : transcriptsList[transcriptIndex].last_successful_pull_date || "";
      transcriptsList[transcriptIndex].has_settings =
        details.status === "missing_settings" ? false : true;

      this.setState((prevState) => ({
        allItems: { ...prevState.allItems, items: transcriptsList },
      }));
    } else if (pendingNewClients.includes(details.client_id)) {
      this.setState({
        pendingNewClients: pendingNewClients.filter(
          (id) => id !== details.client_id
        ),
      });
      this.fetchAllItems({ isNewClient: true });
      this.fetchScheduledItems({ isNewClient: true });
    }
  };

  ispLoginPusherHandler = () => {
    this.ispLoginPusherHandlerSub = onPusher("transcripts-isp-login").subscribe(
      () => {
        this.fetchOrganizations();
      },
      handleError
    );
  };

  transcriptsPusherHandler = () => {
    this.transcriptsPusherHandlerSub = onPusher("transcripts")
      .pipe(
        rxFilter((msg) => msg.data_needed === "status_change"),
        pluck("details")
      )
      .subscribe((details) => {
        if (
          !this.props.permissions.hasUnlimitedPulls &&
          details.category === transcriptStatusTypes.SUCCESS
        ) {
          this.fetchTranscriptsNoticesCount();
        }

        if (details.category === transcriptStatusTypes.SUCCESS) {
          this.fetchPastDueCount();
          this.fetchScheduledItems();
        }

        this.updateTranscriptsRow(details);
      }, handleError);
  };

  shouldShowWelcomeOverlay(user) {
    return (
      isTranscriptsOnly(user) &&
      !!user.preferences?.transcripts?.hide_welcome_overlay === false
    );
  }
}

const css = k`
  .loader-wrapper {
    display: flex;
    justify-content: center;
    padding-top: 25px;
    min-height: 90px;
  }

  .paginator {
    margin-bottom: 1.6rem;
    margin-top: 0.8rem;
    display: flex;
    justify-content: center;
  }

  .hidden-div {
    display: none;
  }
`;
