import React, { useState } from "react";
import { Scoped, k } from "kremling";
import { CpButton, CpModal, CpCheckbox, CpRadio } from "canopy-styleguide!sofe";
import { getAvailableYears } from "./documents.helper";

export default function DocumentPrintDownloadModal({
  download,
  print,
  documentTypes,
  close,
  action,
  showPrintDownloadModal,
}) {
  const [allYears, setAllYears] = useState(true);
  const [desiredYears, setDesiredYears] = useState([]);
  const [allDocs, setAllDocs] = useState(true);
  const [selectedDocs, setSelectedDocs] = useState([]);

  const removeYear = (year) =>
    setDesiredYears(desiredYears.filter((set) => set !== year));
  const addYear = (year) => setDesiredYears([...desiredYears, year]);
  const removeDoc = (doc) =>
    setSelectedDocs(selectedDocs.filter((set) => set !== doc));
  const addDoc = (doc) => setSelectedDocs([...selectedDocs, doc]);

  const years = getAvailableYears(
    allYears,
    allDocs,
    selectedDocs,
    documentTypes
  );
  const buttonActive =
    (allYears || desiredYears.length) && (allDocs || selectedDocs.length);
  const headText = action === "download" ? "Download" : "Print";
  const onClose = () => {
    setDesiredYears([]);
    setAllYears(true);
    setSelectedDocs([]);
    setAllDocs(true);
    close();
  };
  const submit = () => {
    action === "download"
      ? download(allYears, desiredYears, allDocs, selectedDocs)
      : print(allYears, desiredYears, allDocs, selectedDocs);
    return onClose();
  };

  return (
    <CpModal show={showPrintDownloadModal} onClose={onClose}>
      <CpModal.Header title={`${headText} Documents`} />
      <CpModal.Body>
        <Scoped css={css}>
          <div className="cps-subheader-sm">
            Select the document(s) you would like to {headText.toLowerCase()}
          </div>
          <div className="documents-years-selection">
            <CpRadio
              onChange={(value) => setAllDocs(value === "all-docs")}
              value={allDocs ? "all-docs" : "individual-docs"}
              name="all-documents"
            >
              <CpRadio.Item id="all-docs">All documents</CpRadio.Item>
              <CpRadio.Item id="individual-docs">
                Select individual documents
              </CpRadio.Item>
            </CpRadio>
          </div>
          {!allDocs && (
            <div className="individual-documents">
              {documentTypes.map((document) => {
                if (!document.label || !document.availableYears) return false;

                const checked = selectedDocs.includes(document.value);
                return (
                  <div key={document.value} className="document-option">
                    <CpCheckbox
                      checked={checked}
                      onChange={(checked) => {
                        if (checked) {
                          addDoc(document.value);
                        } else {
                          removeDoc(document.value);
                        }
                      }}
                    >
                      {document.label}
                    </CpCheckbox>
                  </div>
                );
              })}
            </div>
          )}
          <div className="cps-subheader-sm">
            Select the year(s) you would like to {headText.toLowerCase()}
          </div>
          <div className="documents-years-selection">
            <CpRadio
              onChange={(value) => setAllYears(value === "all-years")}
              value={allYears ? "all-years" : "individual-years"}
              name="all-years"
            >
              <CpRadio.Item id="all-years">All years</CpRadio.Item>
              <CpRadio.Item id="individual-years">
                Select individual years
              </CpRadio.Item>
            </CpRadio>
          </div>
          {!allYears && (
            <div
              className="individual-years"
              style={{ height: `${(years.length / 2) * 40}px` }}
            >
              {years.map((year) => {
                if (!year) return;

                const checked = desiredYears.includes(year.toString());
                return (
                  <div key={year} className="year-option">
                    <CpCheckbox
                      checked={checked}
                      onChange={(checked) => {
                        if (checked) {
                          addYear(year.toString());
                        } else {
                          removeYear(year.toString());
                        }
                      }}
                    >
                      {year}
                    </CpCheckbox>
                  </div>
                );
              })}
            </div>
          )}
        </Scoped>
      </CpModal.Body>
      <CpModal.Footer>
        <CpButton btnType="primary" disabled={!buttonActive} onClick={submit}>
          {headText}
        </CpButton>
        <CpButton btnType="flat" onClick={onClose}>
          Cancel
        </CpButton>
      </CpModal.Footer>
    </CpModal>
  );
}

const css = k`
  .documents-years-selection {
    padding: 16px 0;
    margin-left: 8px;
  }

  .document-option {
    margin-bottom: 8px;
  }

  .individual-documents {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    padding: 0px 40px 8px;
  }

  .individual-years {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    max-height: 256px;
    padding: 8px 40px;
    min-height: 105px;
  }

  .year-option {
    max-width: 60px;
    margin-bottom: 8px;
  }
`;
