import React from "react";
import Cancelable from "react-disposable-decorator";
import {
  CpButton,
  CpLoader,
  CpModal,
  CpInput,
  CpSelectSingle,
  CprDatepicker,
  CpCheckbox,
  CpTooltip,
  CpIcon,
} from "canopy-styleguide!sofe";
import { isEmpty } from "lodash";
import CprMask from "cpr-mask";
import { UserTenantProps } from "cp-client-auth!sofe";
import { of, forkJoin } from "rxjs";
import { successToast } from "toast-service!sofe";
import { Scoped, k } from "kremling";
import { handleError } from "src/error";
import { notifyAnalytics } from "src/resources/analytics.resource.js";
import { DateTime } from "luxon";
import { featureEnabled } from "feature-toggles!sofe";

import {
  getCafs,
  createUpdateCafs,
  getClient,
  patchClient,
  getContact,
  patchContact,
} from "src/resources/clients.resource.js";
import {
  getIrsOrganizations,
  getSettings,
  createSettings,
  updateSettings,
  deleteTranscripts,
  undoTranscriptsDelete,
  getFiscalYearBeta,
} from "src/resources/transcripts.resource.js";
import PullSchedule from "./common/pull-schedule.component";
import {
  defaultSettings,
  fromJSDateToISO,
  getISODateNow,
  formatCafNumber,
} from "./common/settings.helper";
import ManageFormsSelection from "./common/manage-forms-selection.component";
import OrganizationsDropdown from "./common/organizations-dropdown.component";

const transcriptType = {
  individual: "individual",
  business: "business",
};

const defaultState = {
  business_name: "",
  selectedClient: null,
  selectedTranscriptType: transcriptType.individual,
  taxPayerId: "",
  organizations: [],
  selectedOrg: null,
  saving: false,
  loading: false,
  settings: defaultSettings,
  cafs: [],
  newCaf: "",
  taxPayerIdUpdated: false,
  hasFiscalYearBeta: false,
  contact: {},
};
@Cancelable
@UserTenantProps({
  permissions: {
    editClient: "clients_create_edit",
    hasTranscriptsPull: "transcripts_pull",
    hasUnlimitedPulls: "transcripts_pull_unlimited",
  },
})
export default class TranscriptRequestSettings extends React.Component {
  state = defaultState;

  componentDidUpdate(prevProps) {
    if (!prevProps.show && this.props.show) {
      const isCrmHeirarchy =
        featureEnabled("ft_crm") &&
        this.props.tenant?.crm_status === "crm_hierarchy_complete";
      this.setState({ loading: true });
      this.props.cancelWhenUnmounted(
        forkJoin([
          getCafs(this.props.loggedInUser.id),
          getIrsOrganizations(),
          getFiscalYearBeta(),
        ]).subscribe(
          ([cafNumbers = [], organizations, hasFiscalYearBeta]) => {
            const validOrgs = organizations?.filter((org) => !org.deleted_at);
            let selectedOrg = defaultState.selectedOrg;
            if (validOrgs?.length === 1) {
              selectedOrg = validOrgs[0];
            }

            const cafs = cafNumbers.filter(
              (cafObj) => !!cafObj.caf_number && !cafObj.deleted
            );

            return this.setState({
              cafs,
              organizations: validOrgs,
              selectedOrg,
              loading: false,
              hasFiscalYearBeta,
            });
          },
          (err) => {
            this.setState({ loading: false });
            handleError(err);
          }
        )
      );

      if (this.props?.clientId) {
        this.getClientInfo(this.props.clientId);
      }

      if (isCrmHeirarchy && this.props?.contactId) {
        this.getContactInfo(this.props.contactId);
      }
    }
  }

  renderOrganizations = () => {
    const { organizations, selectedOrg } = this.state;

    return (
      <OrganizationsDropdown
        organizations={organizations}
        selectedOrg={selectedOrg}
        setSelectedOrg={(selectedOrg) => this.setState({ selectedOrg })}
        isSettingsModal
      />
    );
  };

  renderCAFNumber = () => {
    const { cafs, settings, newCaf } = this.state;
    const cafObj = cafs.find((caf) => caf.id === settings.caf_id);

    return (
      <div className="cp-mb-16">
        <label className="cp-flex cp-mb-4">
          CAF number<span className="cp-color-app-primary">*</span>
        </label>
        <div style={{ width: "16rem" }}>
          {isEmpty(cafs) ? (
            <CprMask
              inputClass="input-style-override fs-mask"
              initialValue={newCaf}
              masks={[
                {
                  condition: () => true,
                  pattern: "1111-11111",
                },
              ]}
              onChange={this.onCafChanged}
              placeholder="____-_____"
              validateMethod={(value) => !value || value.length > 8}
              invalidClass="invalid-cpr-mask"
              nonValidMsg="CAF requires 9 digits"
            />
          ) : (
            <CpSelectSingle
              data={cafs}
              appendTo="parent"
              className="select-single-caf_override fs-exclude"
              placeholder="Select a CAF"
              onChange={(caf) =>
                this.setState((prevState) => ({
                  newCaf: caf.caf_number,
                  settings: { ...prevState.settings, caf_id: caf.id },
                }))
              }
              value={cafObj}
              transformData={(caf) => ({
                ...caf,
                name: formatCafNumber(caf.caf_number),
              })}
              contentWidth="sm"
              triggerIsBlock
            />
          )}
        </div>
      </div>
    );
  };

  renderClient = (isCrmHeirarchy) => {
    const { selectedClient, contact } = this.state;
    const contactName = isCrmHeirarchy ? contact?.name : "";

    return isCrmHeirarchy ? (
      <div className="cp-flex cp-mt-16 items-end">
        <div className="cp-flex-column">
          <div className="cp-flex-column cp-mb-16 cp-mr-16">
            <label className="cp-flex cp-mb-4">Client</label>
            <CpInput
              style={{ width: "26.5rem" }}
              value={selectedClient?.display_name || ""}
              disabled
            />
          </div>
          <div className="cp-flex-column cp-mb-16 cp-mr-16">
            <label className="cp-flex cp-mb-4">Contact</label>
            <CpInput
              style={{ width: "26.5rem" }}
              value={contactName || ""}
              disabled
            />
          </div>
        </div>
        {this.renderTaxPayerId()}
      </div>
    ) : (
      <div className="cp-flex cp-mt-16">
        <div className="cp-flex-column cp-mb-16 cp-mr-16">
          <label className="cp-flex cp-mb-4">Client</label>
          <CpInput
            style={{ width: "26.5rem" }}
            value={selectedClient?.name || ""}
            disabled
          />
        </div>
        {this.renderTaxPayerId()}
      </div>
    );
  };

  renderOptions = () => {
    const { settings, hasFiscalYearBeta, selectedClient } = this.state;

    return (
      <div>
        {(featureEnabled("toggle_transcripts_fiscal_year") ||
          hasFiscalYearBeta) &&
          selectedClient?.is_business && (
            <div className="fiscal-select">
              <CpCheckbox
                onChange={this.setCalendar}
                checked={!settings.is_calendar}
              />
              <div style={{ marginTop: "3px" }}>
                Taxpayer is or has been a fiscal year filer
                <CpTooltip
                  text="Taxpayer filed for a period of 12 consecutive months that do not end on the last day of December."
                  position="top"
                >
                  <CpIcon
                    name="information-circle-open-small"
                    fill="var(--cp-color-app-icon)"
                  />
                </CpTooltip>
              </div>
            </div>
          )}
        <div style={{ width: "21rem" }}>
          <label>POA expiration (optional)</label>
          <CprDatepicker
            id="POA_expiration"
            date={settings.poa_expiration_at}
            events={{
              datechange: (date) => this.updatePOAExpiration(date.detail),
            }}
            orientation="bottom"
            removeDateOption
            removeDateText="Remove expiration date"
          />
        </div>
        {this.state.selectedClient && (
          <ManageFormsSelection
            className="cp-pt-24"
            saveSettings={this.saveSettings}
            transcriptSettings={settings}
            isBusiness={this.state.selectedClient.is_business}
          />
        )}
        <PullSchedule
          setSettingsObj={(settings) => this.setState({ settings })}
          settings={settings}
          settingsOnly={true}
        />
      </div>
    );
  };

  saveSettings = (settings) => this.setState({ settings });

  renderTaxPayerId = () => {
    return (
      <div className="cp-mb-16">
        <label>
          {this.state.selectedTranscriptType == transcriptType.individual
            ? "SSN/ITIN"
            : "EIN"}
          <span className="cp-color-app-primary">*</span>
        </label>
        <div style={{ width: "16rem", marginTop: "0.4rem" }}>
          <CprMask
            initialValue={this.state.taxPayerId}
            onChange={(taxPayerId) => this.updateTaxPayerId(taxPayerId)}
            inputProps={
              this.state.selectedTranscriptType === transcriptType.business
                ? { placeholder: "__-_______" }
                : { placeholder: "___-__-____" }
            }
            inputClass="input-style-override fs-mask"
            masks={this.getTINMask()}
            validateMethod={(value) => !value || value.length > 8}
            invalidClass="invalid-cpr-mask"
            nonValidMsg={`${
              this.state.selectedTranscriptType == transcriptType.individual
                ? "SSN/ITIN"
                : "EIN"
            } requires 9 digits`}
          />
        </div>
      </div>
    );
  };

  getClientInfo = (clientId) => {
    const isCrmHeirarchy =
      featureEnabled("ft_crm") &&
      this.props.tenant?.crm_status === "crm_hierarchy_complete";
    this.props.cancelWhenUnmounted(
      getClient(clientId).subscribe((client) => {
        this.setState(
          (prevState) => ({
            selectedClient: client,
            is_business: client.is_business,
            business_name: client.business_name,
            selectedTranscriptType: client.is_business
              ? transcriptType.business
              : transcriptType.individual,
            ...(!isCrmHeirarchy || client.is_business
              ? {
                  taxPayerId: prevState.taxPayerId
                    ? prevState.taxPayerId
                    : client.tin,
                }
              : {}),
          }),
          () => {
            this.getTranscriptsSettings(client.id, client.is_business);
            this.updateForm(isCrmHeirarchy);
          }
        );
      }, handleError)
    );
  };

  getContactInfo = (contactId) => {
    this.props.cancelWhenUnmounted(
      getContact(contactId).subscribe((contact) => {
        this.setState({
          contact,
          taxPayerId: contact.tin,
        });
      }, handleError)
    );
  };

  getTranscriptsSettings = (clientId, isBusiness) => {
    const isCrmHeirarchy =
      featureEnabled("ft_crm") &&
      this.props.tenant?.crm_status === "crm_hierarchy_complete";
    this.props.cancelWhenUnmounted(
      getSettings(
        clientId,
        isBusiness,
        false,
        isCrmHeirarchy,
        this.props.contactId
      ).subscribe((settings) => {
        if (settings) {
          const selectedOrg = this.state.organizations.find(
            (org) => org.id === settings.irs_token_id
          );
          this.setState({
            settings: {
              ...settings,
              forms: settings.forms ? JSON.parse(settings.forms) : null,
            },
            selectedOrg,
          });
        } else {
          this.setState({ settings: { ...defaultSettings } });
        }
      }, handleError)
    );
  };

  onCafChanged = (value) => {
    this.setState(() => ({ newCaf: value?.replace(/\s/g, "") }));
  };

  updateTaxPayerId = (taxPayerId) => {
    this.setState(() => ({ taxPayerId, taxPayerIdUpdated: true }));
  };

  getTINMask = () => {
    return this.state.selectedTranscriptType === transcriptType.business
      ? [
          {
            pattern: "11-1111111",
            condition: () => true,
          },
        ]
      : [
          {
            pattern: "111-11-1111",
            condition: () => true,
          },
        ];
  };

  validScheduleAndStartDate = ({ starting_at, ending_at, cadence }) => {
    if (starting_at && ending_at && cadence) {
      let startDate = DateTime.fromISO(starting_at);
      let endDate = DateTime.fromISO(ending_at);
      let format =
        cadence === "weekly"
          ? "weeks"
          : cadence === "monthly" || cadence === "quarterly"
          ? "months"
          : "years";
      let difference = endDate.diff(startDate, format).toObject();
      if (
        (difference[format] < 1 ||
          (cadence === "quarterly" && difference[format] < 3)) &&
        starting_at === getISODateNow()
      ) {
        return false;
      }
    }
    return true;
  };

  isValidForm = () => {
    const {
      selectedTranscriptType,
      selectedClient,
      business_name,
      taxPayerId,
      selectedOrg,
      settings,
      newCaf,
      cafs,
    } = this.state;

    const scheduleEntered = !settings.cadence ? true : !!settings.starting_at;
    const validSchedule = !settings.cadence
      ? true
      : this.validScheduleAndStartDate(settings);
    const clientEntered =
      !!selectedClient &&
      ((selectedTranscriptType === transcriptType.individual &&
        !!selectedClient.name) ||
        !!selectedClient.display_name ||
        !!business_name);
    const cafObj = cafs.find((caf) => caf.id === settings.caf_id);
    const cafEntered = newCaf?.length >= 9 || !!cafObj;

    return (
      cafEntered &&
      clientEntered &&
      taxPayerId &&
      taxPayerId.length === 9 &&
      !!selectedOrg &&
      scheduleEntered &&
      validSchedule
    );
  };

  updateForm = (isCrmHeirarchy) => {
    this.setState(({ selectedClient }) => {
      const taxPayerId =
        selectedClient.tin ||
        (selectedClient.is_business ? selectedClient.ein : selectedClient.ssn);

      return selectedClient
        ? {
            ...(!isCrmHeirarchy || selectedClient.is_business
              ? { taxPayerId }
              : {}),
            selectedTranscriptType: selectedClient.is_business
              ? transcriptType.business
              : transcriptType.individual,
          }
        : {
            taxPayerId: null,
            ...(!isCrmHeirarchy || selectedClient.is_business
              ? { taxPayerId: null }
              : {}),
            selectedTranscriptType: transcriptType.individual,
          };
    });
  };

  updatePOAExpiration = (date) => {
    if (!date) {
      this.setState((prevState) => ({
        settings: { ...prevState.settings, poa_expiration_at: null },
      }));
    }

    this.setState((prevState) => ({
      settings: {
        ...prevState.settings,
        poa_expiration_at: fromJSDateToISO(date),
      },
    }));
  };

  saveTranscriptsSettings = (isCrmHeirarchy) => {
    const { selectedClient, selectedOrg, settings, cafs, newCaf } = this.state;

    const onSuccess = (savedSettings) => {
      this.closeModal();
      this.props.onSaveCb?.(savedSettings);
    };

    let settingsCall = createSettings;

    if (settings.id) {
      settingsCall = updateSettings;
    }

    const isBusiness = selectedClient.is_business;

    const settingsBody = {
      ...settings,
      irs_token_id: selectedOrg.id,
      client_id: selectedClient.id,
      contact_id:
        isCrmHeirarchy && !isBusiness ? this.props.contactId : undefined,
    };

    // call createUpdateCafs when a new cafs needs to be created in the db, so we can then use the id of that caf to set settings.caf_id
    this.props.cancelWhenUnmounted(
      (isEmpty(cafs) && newCaf
        ? createUpdateCafs([{ caf_number: newCaf }], this.props.loggedInUser.id)
        : of([])
      ).subscribe(
        (newCafs) => {
          // if a new caf was created then set the caf_id in settings to that caf id returned from backend
          if (newCafs?.length) settingsBody.caf_id = newCafs[0]?.id;

          settingsCall(settingsBody).subscribe(
            (savedSettings) => {
              successToast(
                `Your transcripts request settings for ${
                  selectedClient?.name || "your client"
                } have successfully been saved.`
              );
              onSuccess(savedSettings);
            },
            (err) => {
              this.setState({ saving: false });
              handleError(err);
            }
          );
        },
        (err) => {
          this.setState({ saving: false });
          handleError(err);
        }
      )
    );
  };

  onNext = () => {
    const { cancelWhenUnmounted } = this.props;
    const {
      selectedClient,
      taxPayerId: tin,
      taxPayerIdUpdated,
      contact,
    } = this.state;
    const isCrmHeirarchy =
      featureEnabled("ft_crm") &&
      this.props.tenant?.crm_status === "crm_hierarchy_complete";

    this.setState({ saving: true });

    if (
      taxPayerIdUpdated &&
      this.props.permissions.editClient &&
      (!isCrmHeirarchy || selectedClient.is_business)
    ) {
      cancelWhenUnmounted(
        patchClient(selectedClient.id, { tin }).subscribe(() => {
          this.saveTranscriptsSettings(isCrmHeirarchy);
        }, handleError)
      );
    } else if (
      taxPayerIdUpdated &&
      this.props.permissions.editClient &&
      isCrmHeirarchy &&
      !selectedClient.is_business
    ) {
      patchContact(contact.id, {
        ...contact,
        tin,
      }).subscribe(
        () => this.saveTranscriptsSettings(isCrmHeirarchy),
        handleError
      );
    } else {
      this.saveTranscriptsSettings(isCrmHeirarchy);
    }
  };

  deleteTranscripts = () => {
    const { selectedClient } = this.state;

    this.props.cancelWhenUnmounted(
      deleteTranscripts(selectedClient?.id).subscribe(() => {
        this.props.onDeleteCb?.(selectedClient?.id);
        notifyAnalytics("transcripts.deleted", {
          client_type: selectedClient?.is_business ? "business" : "individual",
        });
        this.closeModal();
        successToast({
          message: `${
            selectedClient?.name || "Your client"
          }'s transcripts have been successfully deleted`,
          actionText: "Undo",
          actionCallback: () => this.undoDelete(selectedClient.id),
        });
      }, handleError)
    );
  };

  undoDelete = (clientId) => {
    this.props.cancelWhenUnmounted(
      undoTranscriptsDelete(clientId).subscribe(() => {
        this.props.onDeleteCb?.(this.state.selectedClient?.id, true);
      }, handleError)
    );
  };

  closeModal = () => {
    this.props.onClose?.();
    this.setState(defaultState);
  };

  setCalendar = (isFiscal) =>
    this.setState((prevState) => ({
      settings: { ...prevState.settings, is_calendar: !isFiscal },
    }));

  render() {
    const { show = false, permissions, scheduleTabActive } = this.props;
    const { saving, loading } = this.state;
    const isCrmHeirarchy =
      featureEnabled("ft_crm") &&
      this.props.tenant?.crm_status === "crm_hierarchy_complete";

    return (
      <CpModal show={show} onClose={this.closeModal} width={500}>
        <CpModal.Header title="Transcripts Settings" />
        <CpModal.Body>
          {loading ? (
            <div className="cp-pt-40">
              <CpLoader />
            </div>
          ) : (
            <Scoped css={css}>
              <div className="tr-subheader">Request Info</div>
              {this.renderClient(isCrmHeirarchy)}
              <div className="cp-flex">
                {this.renderOrganizations()}
                {this.renderCAFNumber()}
              </div>
              {this.renderOptions()}
            </Scoped>
          )}
        </CpModal.Body>
        <CpModal.Footer>
          <div className="cp-flex-spread">
            <div>
              <CpButton
                btnType="primary"
                onClick={this.onNext}
                disabled={!this.isValidForm() || saving}
              >
                Save changes
              </CpButton>
              <CpButton
                className="cp-ml-8"
                btnType="flat"
                onClick={this.closeModal}
              >
                Cancel
              </CpButton>
            </div>
            {!scheduleTabActive && (
              <CpTooltip
                text={
                  permissions.hasUnlimitedPulls
                    ? "Delete all past and current transcripts"
                    : "Unable to delete while on a free trial account"
                }
                position="top"
              >
                <span>
                  <CpButton
                    icon="crud-trash-small"
                    aria-label="Delete transcripts"
                    disabled={!permissions.hasUnlimitedPulls}
                    onClick={this.deleteTranscripts}
                  />
                </span>
              </CpTooltip>
            )}
          </div>
        </CpModal.Footer>
      </CpModal>
    );
  }
}

const css = k`
  .tr-subheader {
    color: var(--cp-color-primary-text);
    font-weight: bold;
  }

  .input-style-override {
    display: block;
    width: 100%;
    height: 3.2rem;
    padding: 0.2rem 1.2rem 0.1rem 1.2rem;
    font-size: 1.3rem;
    background-color: #fff;
    background-image: none;
    border: 0.1rem solid #dfdfdf;
    border-radius: 0.5rem;
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  }

  .select-single_override {
    width: 26.5rem;
  }

  .select-single_override > div > button {
    max-width: 26.5rem;
  }

  .select-single-caf_override {
    width: 16rem;
  }

  .select-single-caf_override > div > button {
    max-width: 16rem;
  }

  .invalid-cpr-mask {
    color: red;
  }

  .invalid-cpr-mask input {
    border: 0.1rem solid red;
  }

  .fiscal-select {
    margin-bottom: 1.6rem;
    display: flex;
    align-items: center;
  }
`;
