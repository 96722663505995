import React from "react";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";
import { UserTenantProps, hasAccess } from "cp-client-auth!sofe";

import GeneralDashboard from "./dashboard/general-dashboard.component.js";
import ClientTranscripts from "./client-transcripts/client-transcripts.component.js";
import { HelpCenter } from "./help-center/help-center.component";
import IRSAuthorized from "./e-services/irs-authorized.component";

@UserTenantProps()
export default class Root extends React.Component {
  render() {
    const hasTranscriptsPermission = hasAccess(this.props.loggedInUser)(
      "transcripts"
    );

    return hasTranscriptsPermission ? (
      <HashRouter>
        <Switch>
          <Route
            path="/transcripts/list"
            render={(props) => <GeneralDashboard {...props} />}
          />
          <Route
            path="/client/:clientId/transcripts/reports"
            render={(props) => (
              <ClientTranscripts {...props} selectedView="reports" />
            )}
          />
          <Route
            path="/client/:clientId/transcripts/documents"
            render={(props) => (
              <ClientTranscripts {...props} selectedView="documents" />
            )}
          />
          <Route
            path="/transcripts/help-center"
            render={() => <HelpCenter topic={"caf-error"} />}
          />
          <Route
            path="/transcripts/help-center/tin-name-error"
            render={() => <HelpCenter topic={"tin-name-error"} />}
          />
          <Route
            path="/transcripts/irs-authorized"
            render={(props) => <IRSAuthorized {...props} />}
          />
        </Switch>
      </HashRouter>
    ) : (
      <HashRouter>
        <Redirect to="/403" />
      </HashRouter>
    );
  }
}
