import {
  cloneDeep,
  each,
  find,
  groupBy,
  map,
  sortBy,
  isEmpty,
  flatMap,
  uniq,
} from "lodash";

export const transcriptKeys = {
  ACCOUNT_TRANSCRIPT: "account_transcript",
  WAGE_AND_INCOME: "wage_and_income",
  TAX_RETURN_TRANSCRIPT: "tax_return_transcript",
  RECORD_OF_ACCOUNT: "record_of_account",
  VERIFICATION_OF_NON_FILING: "verification_of_non-filing",
};

export const pullMethodKeys = {
  ACTR: { title: "Account Transcript", key: "actr" },
  WAID: { title: "Wage and Income", key: "waid" },
  RETR: { title: "Tax Return Transcript", key: "retr" },
  RECA: { title: "Record of Account", key: "reca" },
  VENF: { title: "Verification of Non-Filing-filing", key: "venf" },
  "Account Transcript": {
    title: "Account Transcript",
    key: transcriptKeys.ACCOUNT_TRANSCRIPT,
  },
  "Wage and Income": {
    title: "Wage and Income",
    key: transcriptKeys.WAGE_AND_INCOME,
  },
  "Tax Return Transcript": {
    title: "Tax Return Transcript",
    key: transcriptKeys.TAX_RETURN_TRANSCRIPT,
  },
  "Record of Account": {
    title: "Record of Account",
    key: transcriptKeys.RECORD_OF_ACCOUNT,
  },
  "Verification of Non-Filing": {
    title: "Verification of Non-Filing",
    key: transcriptKeys.VERIFICATION_OF_NON_FILING,
  },
};

export const transcriptTypes = {
  [transcriptKeys.ACCOUNT_TRANSCRIPT]: {
    order: 1,
    title: "Account Transcript",
    apiType: "ACTR",
  },
  [transcriptKeys.WAGE_AND_INCOME]: {
    order: 2,
    title: "Wage and Income",
    apiType: "WAID",
  },
  [transcriptKeys.TAX_RETURN_TRANSCRIPT]: {
    order: 3,
    title: "Tax Return Transcript",
    apiType: "RETR",
  },
  [transcriptKeys.RECORD_OF_ACCOUNT]: {
    order: 4,
    title: "Record of Account",
    apiType: "RECA",
  },
  [transcriptKeys.VERIFICATION_OF_NON_FILING]: {
    order: 5,
    title: "Verification of Non-Filing",
    apiType: "VENF",
  },
};

export const businessTranscriptTypes = {
  [transcriptKeys.ACCOUNT_TRANSCRIPT]: {
    order: 1,
    title: "Account Transcript",
    apiType: "ACTR",
  },
  [transcriptKeys.TAX_RETURN_TRANSCRIPT]: {
    order: 3,
    title: "Tax Return Transcript",
    apiType: "RETR",
  },
  [transcriptKeys.RECORD_OF_ACCOUNT]: {
    order: 4,
    title: "Record of Account",
    apiType: "RECA",
  },
};

export function sortSubDocuments(docGroup, key) {
  // Remove all ugly yellow boxes
  each(docGroup, (doc) => {
    doc.content = doc.content.replace(new RegExp('bgcolor="yellow"', "g"), "");
  });

  let yearGroup = groupBy(docGroup, (doc) => doc.year);

  // Convert Object to Array
  yearGroup = map(yearGroup, (group) => sortBy(group, (doc) => doc.subtype));

  // Sort by year
  yearGroup = sortBy(yearGroup, (group) => group[0].year * -1);

  // Preserve order of the groups
  const TYPE = find(transcriptTypes, (type) => type.title == key);
  const orderVal = TYPE ? TYPE.order : 10;

  // List of years that can be printed
  const years = yearGroup.map((group) => parseInt(group[0].year, 10));

  let docs = {
    order: orderVal,
    documents: yearGroup,
    selectedYears: years,
    availableYears: cloneDeep(years),
  };

  return docs;
}

// build an array of available years based on which documents are selected
export function getAvailableYears(
  allYears,
  allDocs,
  selectedDocs,
  documentTypes
) {
  return allYears
    ? []
    : uniq(
        flatMap(
          documentTypes.filter(
            (doc) =>
              allDocs ||
              isEmpty(selectedDocs) ||
              selectedDocs.includes(doc.value)
          ),
          (docType) => docType.availableYears
        )
      )
        .sort()
        .reverse();
}
