import React from "react";
import { CpSelectSingle } from "canopy-styleguide!sofe";
import { isOrgActive } from "src/common/irs-status.component";

export default function OrganizationsDropdown({
  organizations,
  selectedOrg,
  setSelectedOrg,
  isSettingsModal,
}) {
  return (
    <div className="cp-mr-16">
      <div className="cp-mb-4">
        Your organization
        <span className="cp-color-app-primary"> *</span>
      </div>
      <CpSelectSingle
        className="select-single_override"
        data={organizations}
        placeholder="Select an organization"
        onChange={setSelectedOrg}
        value={selectedOrg}
        error={
          !isSettingsModal &&
          selectedOrg &&
          !isOrgActive(selectedOrg) &&
          "Please sign in"
        }
        transformData={(org) => {
          const isActive = isOrgActive(org);

          return {
            ...org,
            subName: isActive ? "Signed in" : "Signed out",
            name: org.dba,
            icon: isActive ? "shape-circle-closed" : "shape-circle-open-small",
            iconColor: isActive && "var(--cp-color-app-primary)",
            hideTriggerIcon: true,
            disabled: !isActive && !isSettingsModal,
          };
        }}
        triggerIsBlock
      />
    </div>
  );
}
