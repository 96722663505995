import React from "react";
import { CpIcon } from "canopy-styleguide!sofe";
import { Scoped, always, k } from "kremling";

export default function MenuItem({
  id,
  title,
  selectedItem,
  setSelectedItem,
  viewKey,
  unsuccessful,
}) {
  return (
    <Scoped css={css}>
      <div
        data-testid="menu-item"
        key={id}
        onClick={() => setSelectedItem(id, viewKey)}
        className={always("menu-item-slat").maybe(
          "active-item",
          selectedItem === id
        )}
      >
        {title}
        {unsuccessful && (
          <CpIcon
            className="cp-ml-4"
            name="alert-triangle-open-large"
            fill="var(--cp-color-app-warning-text)"
          />
        )}
      </div>
    </Scoped>
  );
}

const css = k`
  .menu-item-slat {
    padding: 10px 8px 10px 40px;
    cursor: pointer;
    display: flex;
    align-items: center;
    background-color: var(--cp-color-well-l2-bg);
  }

  .menu-item-slat:hover {
    background-color: var(--cp-color-menu-hover-bg);
  }

  .menu-item-slat.active-item {
    font-weight: bold;
  }
`;
