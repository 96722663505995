import React from "react";
import Cancelable from "react-disposable-decorator";
import { Scoped, k, a } from "kremling";
import { partial, without, isEmpty } from "lodash";
import { CpIcon, CpLoader, CpButton, CpInput } from "canopy-styleguide!sofe";

import {
  getFilterOptions,
  getScheduleFilterOptions,
} from "../resources/transcripts.resource";

@Cancelable
export default class FilterMenu extends React.Component {
  state = {
    sortDirection: null,
    filterOptions: [],
    allFilterOptions: [],
    filterSearchValue: "",
    selections: [],
    filterOptionsLoaded: false,
  };

  componentDidMount() {
    if (this.props.persistingSort)
      this.setState({ sortDirection: this.props.persistingSort });
    if (!isEmpty(this.props.persistingFilters))
      this.setState({ selections: this.props.persistingFilters });

    const obs = this.props.isScheduledList
      ? getScheduleFilterOptions
      : getFilterOptions;

    this.props.cancelWhenUnmounted(
      obs(this.props.column.columnType).subscribe((filterOptions) => {
        this.setState({
          filterOptions,
          allFilterOptions: filterOptions,
          filterOptionsLoaded: true,
        });
      })
    );
  }

  render() {
    const {
      filterSearchValue,
      filterOptions,
      sortDirection,
      filterOptionsLoaded,
      allFilterOptions,
    } = this.state;
    const { column } = this.props;
    return (
      <Scoped css={css}>
        <div className="filter-menu" onClick={(e) => e.stopPropagation()}>
          {this.props.column.sortable && (
            <>
              <div className="sort-options">
                <div
                  className={a("sort-option").m(
                    "selected-option",
                    sortDirection === "+"
                  )}
                  onClick={() => this.changeSortDirection("+")}
                >
                  <div>
                    {column.columnType === "cadence"
                      ? "Sort More Frequent - Less Frequent"
                      : "Sort A - Z"}
                  </div>
                  {sortDirection === "+" && (
                    <div style={{ marginTop: "-4px" }}>
                      <CpIcon name="checkmark-small" />
                    </div>
                  )}
                </div>
                <div
                  className={a("sort-option").m(
                    "selected-option",
                    sortDirection === "-"
                  )}
                  onClick={() => this.changeSortDirection("-")}
                >
                  <div>
                    {column.columnType === "cadence"
                      ? "Sort Less Frequent - More Frequent"
                      : "Sort Z - A"}
                  </div>
                  {sortDirection === "-" && (
                    <div style={{ marginTop: "-4px" }}>
                      <CpIcon name="checkmark-small" />
                    </div>
                  )}
                </div>
              </div>
              <div className="cp-ph-16">
                <hr className="cp-mv-0" />
              </div>
            </>
          )}
          {filterOptionsLoaded ? (
            <div>
              <div className="selection-search-input">
                <CpInput
                  value={filterSearchValue}
                  onChange={this.filterSelections}
                  placeholder="Search"
                  icon="misc-magnifying-glass"
                  onClear={() => {
                    this.filterSelections("");
                  }}
                />
              </div>
              <div className="filter-options">
                {filterOptions.map((selection) => {
                  const isSelected = this.valueIsSelected(selection.id);

                  return (
                    <div
                      key={selection.id}
                      onClick={partial(this.toggleSelection, selection.id)}
                      className={a("filter-option").m(
                        "selected-option",
                        isSelected
                      )}
                    >
                      <div className="filter-option-name">{selection.name}</div>
                      {isSelected && (
                        <div style={{ marginTop: "-4px" }}>
                          <CpIcon name="checkmark-small" />
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          ) : (
            <div style={{ margin: "16px" }}>
              <CpLoader />
            </div>
          )}
          <div className="apply-filter">
            <div>
              <CpButton
                onClick={this.applyFilters}
                btnType="primary"
                className="cp-mr-8"
              >
                Apply
              </CpButton>
              <CpButton onClick={this.props.close} btnType="flat">
                Cancel
              </CpButton>
            </div>
            <CpButton
              onClick={() => {
                this.setState({
                  selections: [],
                  sortDirection: null,
                  filterSearchValue: "",
                  filterOptions: allFilterOptions,
                });
              }}
              btnType="flat"
            >
              Clear
            </CpButton>
          </div>
        </div>
      </Scoped>
    );
  }

  applyFilters = () => {
    let persistSort = false;
    if (!this.state.sortDirection && !this.props.persistingSort)
      persistSort = true;
    this.props.updateFilterParams(
      this.props.column,
      this.state.sortDirection,
      this.state.selections,
      persistSort
    );
    this.props.close();
  };

  changeSortDirection = (sortDirection) => {
    this.setState((prevState) => {
      return {
        sortDirection:
          prevState.sortDirection === sortDirection ? null : sortDirection,
      };
    });
  };

  filterSelections = (value) => {
    this.setState({
      filterSearchValue: value,
      filterOptions: this.state.allFilterOptions.filter((selection) =>
        selection.name
          .toString()
          .toLowerCase()
          .includes(value.toString().toLowerCase())
      ),
    });
  };

  toggleSelection = (selection) => {
    let { selections } = this.state;

    if (selections === null || typeof selections === "undefined") {
      selections = [];
    }

    selections.includes(selection)
      ? (selections = without(selections, selection))
      : selections.push(selection);

    this.setState({
      selections,
    });
  };

  valueIsSelected = (value) => {
    const { selections } = this.state;

    if (selections === null || typeof selections === "undefined") return false;
    else return selections.includes(value);
  };
}

const css = k`
  .filter-menu {
    position: absolute;
    color: var(--cp-color-app-primary-text);
    top: 51px;
    border: 1px solid var(--cp-color-card-border);
    width: 300px;
    background: var(--cp-color-card-bg);
    z-index: 1000;
    border-radius: 5px;
    left: 0;
    border: none;
    box-shadow: 0 4px 8px 0 var(--cp-color-card-border), 0 2px 6px 0 var(--cp-color-card-border);
  }

  .sort-options {
    padding: 8px 16px;
    font-size: 13px;
  }

  .sort-option {
    display: flex;
    padding-bottom: 8px;
    cursor: pointer;
    font-weight: normal;
    justify-content: space-between;
  }

  .selected-option {
    color: var(--cp-color-app-callout-text);
  }

  .selection-search-input {
    padding: 12px 16px 16px 16px;
  }

  .apply-filter {
    border-top: 1px solid var(--cp-color-card-border);
    background: var(--cp-color-card-bg);
    padding: 8px 16px;
    height: 6.4rem;
    font-size: 13px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .apply-filter button:focus {
    outline: 0;
  }

  .filter-options {
    font-weight: normal;
    max-height: 200px;
    overflow-y: auto;
    padding: 0 16px 0 8px;
  }

  .filter-option {
    display: flex;
    justify-content: space-between;
    height: 40px;
    padding: 4px 8px;
    cursor: pointer;
  }

  .filter-option-name {
    max-width: 218px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;
