import React from "react";
import { map, cloneDeep } from "lodash";
import { CpIcon } from "canopy-styleguide!sofe";
import { Scoped, k, a } from "kremling";
import { Link } from "react-router-dom";

import MenuItem from "./menu-item.component.js";
import UnfetchableDocs from "src/client-transcripts/unfetchable-docs.component.js";
import VersionSelector from "./version-selector.component";
import { viewKeys } from "./wrapper.helper.js";

export default function GutterNavigator({
  selectedViewKey,
  disabledTabs,
  cafStatuses,
  versions,
  selectedVersion,
  setSelectedVersion,
  selectedItem,
  setSelectedItem,
  menuItems,
  comparedVersion,
  setComparedVersion,
  showCompareVersionSelector,
  setComparingVersions,
  comparingVersions,
  unsuccessfulReports,
  clientId,
}) {
  const getMenuItems = (viewKey, unsuccessfulList = []) => {
    const menuItemsForKey = cloneDeep(menuItems[viewKey]);
    return map(menuItemsForKey, (item, itemKey) => (
      <MenuItem
        key={itemKey}
        {...item}
        id={itemKey}
        selectedItem={selectedItem}
        setSelectedItem={setSelectedItem}
        viewKey={viewKey}
        unsuccessful={unsuccessfulList.includes(itemKey)}
      />
    ));
  };

  return (
    <Scoped css={css}>
      <div className="left-hand-nav" data-testid="gutter-nav">
        <div className="main-navigation">
          <div>
            <Link
              className={a("main-nav-tab").maybe(
                "selected-tab",
                selectedViewKey === viewKeys.reports && !disabledTabs
              )}
              to={`/client/${clientId}/transcripts/reports`}
            >
              <CpIcon
                name="misc-bar-graph"
                style={{
                  marginTop: "-4px",
                  marginRight: "8px",
                }}
              />
              Reports
            </Link>
            <div className="nav-menu-items">
              {selectedViewKey === viewKeys.reports &&
                !disabledTabs &&
                getMenuItems(viewKeys.reports, unsuccessfulReports)}
            </div>
          </div>
          <div>
            <Link
              className={a("main-nav-tab").maybe(
                "selected-tab",
                selectedViewKey === viewKeys.documents && !disabledTabs
              )}
              to={`/client/${clientId}/transcripts/documents`}
            >
              <CpIcon
                name="file-document"
                style={{
                  marginTop: "-4px",
                  marginRight: "8px",
                }}
              />
              Documents
            </Link>
            <div className="nav-menu-items">
              {selectedViewKey === viewKeys.documents &&
                !disabledTabs &&
                getMenuItems(viewKeys.documents)}
            </div>
          </div>
        </div>
        <div className="version-select-card">
          <VersionSelector
            versions={versions}
            selectedVersion={selectedVersion}
            setSelectedVersion={setSelectedVersion}
            comparedVersion={comparedVersion}
            setComparedVersion={setComparedVersion}
            showCompareVersionSelector={showCompareVersionSelector}
            setComparingVersions={setComparingVersions}
            comparingVersions={comparingVersions}
          />
        </div>
        {cafStatuses && <UnfetchableDocs cafStatuses={cafStatuses} />}
      </div>
    </Scoped>
  );
}

const css = k`
  .left-hand-nav {
    padding-top: 8px;
    margin-left: 24px;
    height: 100%;
    z-index: 90;
    width: 255px;
    position: fixed;
    height: calc(100vh - 12.5rem);
    overflow-y: auto;
  }

  .flexed {
    display: flex;
    justify-content: center;
  }

  .main-nav-tab {
    padding-left: 24px;
    cursor: pointer;
    line-height: 48px;
    color: var(--cp-color-app-primary-text);
  }

  .main-nav-tab:hover, .main-nav-tab:focus {
    text-decoration: none;
  }

  .main-navigation {
    margin-top: 16px;
    min-width: 240px;
    background-color: var(--cp-color-card-bg);
    border: 1px solid var(--cp-color-card-bg);
    color: var(--cp-color-app-primary-text);
  }

  .selected-tab {
    color: var(--cp-color-button-icon-active-text);
    font-weight: 600;
  }

  .version-select-card {
    margin-top: 16px;
    min-width: 240px;
    background-color: var(--cp-color-card-bg);
    border: 1px solid var(--cp-color-card-border);
    color: var(--cp-color-app-primary-text);
    padding: 16px 24px 24px 16px;
  }
`;
