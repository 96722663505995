import React, { useEffect, useState, useMemo, useCallback } from "react";
import { filter, isEmpty, map } from "lodash";
import {
  CpLoader,
  CpButton,
  CpDropdown,
  CpIcon,
  CpHeader,
  CpCard,
} from "canopy-styleguide!sofe";
import { handleError } from "src/error";
import { Scoped, k } from "kremling";
import { map as rxMap } from "rxjs/operators";
import { useHasAccess } from "cp-client-auth!sofe";

import { getReports } from "../../resources/transcripts.resource.js";
import { ReportsTable } from "./reports-table.component.js";
import SaveToFilesModal from "../save-files/save-to-files-modal.component";
import ReportsPrintDownloadModal from "./reports-print-download-modal.component";
import TranscriptsEmptyState from "../transcripts-empty-state.component";
import { notifyAnalytics } from "../../resources/analytics.resource";
import { reportsDownload, reportsPrint } from "../capture-operations";

export const Reports = ({
  client,
  selectedItem,
  version,
  showHideRequestModal,
  showHideSettingsModal,
  disableRequestBtn,
  setUnsuccessfulReports,
  unsuccessfulReports,
  setReportsMenuItems,
  setReports,
  reports = {},
}) => {
  const [reportsLoading, setReportsLoading] = useState(true);
  const [showFilesModal, setShowFilesModal] = useState(false);
  const [showPrintDownloadModal, setShowPrintDownloadModal] = useState(false);
  const [modalAction, setModalAction] = useState("print");
  const hasClientFilesAccess = useHasAccess(
    ["files_client", "files_upload_move", "files"],
    true
  );
  const hasTranscriptsPull = useHasAccess("transcripts_pull");

  const createReportObjects = useCallback(
    (reports) => {
      const unsuccessfulReports = filter(
        reports,
        (report) => !report.successful
      ).map((report) => report.type);
      setUnsuccessfulReports(unsuccessfulReports);

      // combine all reports into categories based on their report type and subtypes (tables property)
      return reports
        .filter((report) => report.html)
        .reduce((reportObj, report) => {
          if (reportObj[report.type]) {
            // add another table to this report type category
            reportObj[report.type].tables = [
              ...reportObj[report.type].tables,
              report,
            ];
          } else {
            // if report type isn't in obj already, add it with a couple parent level properties along with the first table in that category
            reportObj = {
              ...reportObj,
              [report.type]: {
                tables: [report],
                successful: !unsuccessfulReports.includes(report.type),
                title: report.type,
              },
            };
          }

          return reportObj;
        }, {});
    },
    [setUnsuccessfulReports]
  );

  useEffect(() => {
    setReportsLoading(true);

    const subscription = getReports(version.id, client.is_business)
      .pipe(rxMap((reports) => createReportObjects(reports)))
      .subscribe((reports) => {
        setReports(reports);
        setReportsLoading(false);
      }, handleError);

    return () => subscription.unsubscribe?.();
  }, [
    client.is_business,
    setUnsuccessfulReports,
    version.id,
    createReportObjects,
    setReportsMenuItems,
    setReports,
  ]);

  const reportSuccessful = useMemo(
    () => !unsuccessfulReports.includes(reports[selectedItem]?.type),
    [unsuccessfulReports, reports, selectedItem]
  );

  const downloadReports = useCallback(
    (allTypes, types) => {
      notifyAnalytics("transcripts.reports_downloaded", {
        client_type: client.is_business ? "business" : "individual",
      });

      setShowPrintDownloadModal(false);

      const filter = {
        types: !isEmpty(types) && !allTypes ? types : [],
      };

      reportsDownload(version.id, filter, client.is_business);
    },
    [client.is_business, version.id]
  );

  const printReports = useCallback(
    (resourceUrl) => {
      notifyAnalytics("transcripts.reports_printed", {
        client_type: client.is_business ? "business" : "individual",
      });

      setShowPrintDownloadModal(false);

      reportsPrint(resourceUrl);
    },
    [client.is_business]
  );

  const reportTypeOptions = useMemo(
    () =>
      map(reports, (report) => ({ value: report.title, label: report.title })),
    [reports]
  );

  return (
    <Scoped css={css}>
      <div className="reports-container">
        {reportsLoading ? (
          <CpLoader size="lg" center className="cp-mt-24" />
        ) : (
          <div className="table-container">
            <SaveToFilesModal
              showSaveToFilesModal={showFilesModal}
              client={client}
              close={() => setShowFilesModal(false)}
              type="reports"
              versionId={version.id}
              reportTypeOptions={reportTypeOptions}
            />
            <ReportsPrintDownloadModal
              showPrintDownloadModal={showPrintDownloadModal}
              close={() => setShowPrintDownloadModal(false)}
              download={downloadReports}
              action={modalAction}
              print={printReports}
              versionId={version.id}
              isBusiness={client.is_business}
              reportTypeOptions={reportTypeOptions}
            />
            {reportSuccessful && reports[selectedItem]?.tables?.length ? (
              reports[selectedItem].tables.map((tableData, i) => {
                const tableIdentifier = `${tableData.reportType}-${i}`;

                return (
                  <CpCard
                    id={tableIdentifier}
                    key={tableIdentifier}
                    className="cp-mb-24 cp-mr-16"
                  >
                    <CpHeader>
                      <div className="report-table-headers">
                        {tableData.title}
                      </div>
                      <div className="cp-flex-center">
                        {hasTranscriptsPull && (
                          <CpButton
                            btnType="primary"
                            className="cp-mr-12"
                            onClick={() => showHideRequestModal(true)}
                            disabled={disableRequestBtn}
                          >
                            Request transcripts
                          </CpButton>
                        )}
                        <CpDropdown
                          contentWidth={200}
                          position="bottom-end"
                          renderTrigger={({ toggle }) => (
                            <CpButton
                              icon="misc-kabob"
                              onClick={toggle}
                              aria-label="Transcripts actions"
                            />
                          )}
                          renderContent={() => (
                            <div className="cp-select-list">
                              <button
                                onClick={() => {
                                  setModalAction("print");
                                  setShowPrintDownloadModal(true);
                                }}
                              >
                                <CpIcon
                                  className="cp-select-list__icon-left"
                                  name="misc-printer"
                                />
                                Print
                              </button>
                              <button
                                onClick={() => {
                                  setModalAction("download");
                                  setShowPrintDownloadModal(true);
                                }}
                              >
                                <CpIcon
                                  className="cp-select-list__icon-left"
                                  name="af-open-down"
                                />
                                Download
                              </button>
                              {hasClientFilesAccess && (
                                <button onClick={() => setShowFilesModal(true)}>
                                  <CpIcon
                                    className="cp-select-list__icon-left"
                                    name="folder-c"
                                  />
                                  Save to Canopy Files
                                </button>
                              )}
                              <button
                                onClick={() => showHideSettingsModal(true)}
                              >
                                <CpIcon
                                  className="cp-select-list__icon-left"
                                  name="misc-gear"
                                />
                                Transcripts Settings
                              </button>
                            </div>
                          )}
                        />
                      </div>
                    </CpHeader>
                    <ReportsTable reportTableHtml={tableData.html} />
                  </CpCard>
                );
              })
            ) : (
              <div className="no-reports">
                <TranscriptsEmptyState
                  showHideRequestModal={showHideRequestModal}
                  title={reports[selectedItem]?.title}
                  headText="No report found"
                  subheadText="This type of report doesn't exist for this client."
                  requestBtnDisabled={reportSuccessful && disableRequestBtn}
                  chatSupport={!reportSuccessful}
                />
              </div>
            )}
          </div>
        )}
      </div>
    </Scoped>
  );
};

const css = k`
  .reports-container {
    width: calc(100vw - 350px);
    margin-left: 275px;
    padding: 2.4rem 1rem 2.4rem 0;
  }

  .table-container {
    width: 100%;
    padding-left: 24px;
  }

  .no-reports {
    margin-top: -32px;
    width: 90rem;
    margin-left: -24px;
  }

  .report-table-headers {
    font-size: 20px;
    font-weight: 400;
  }
`;
