import React, { lazy, Suspense, useMemo, useState } from "react";
import { useCss, k } from "kremling";
import { CpIcon, CpButton } from "canopy-styleguide!sofe";

const TNFreeTrialPaywallModal = lazy(() =>
  SystemJS.import("global-settings!sofe").then((globalSettings) =>
    globalSettings.getTNFreeTrialPaywallModal()
  )
);

export default function TNFreeTrialUpgradeBanner({
  transcriptsCount,
  noticesCount,
  noticesLimitReached,
  transcriptsLimitReached,
}) {
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const scoped = useCss(css);

  const bannerMsg = useMemo(() => {
    let count = `${transcriptsCount?.successful_count || 0}/${
      transcriptsCount?.limit || 0
    } Transcripts pulled`;
    let text =
      "The maximum number of transcripts have been pulled. Please upgrade to request more transcripts.";
    const hashUrl = window.location.hash;

    if (hashUrl.includes("client")) {
      if (noticesLimitReached && transcriptsLimitReached) {
        count = "Out of actions";
        text =
          "Please upgrade your account to create unlimited transcripts and notices.";
      } else if (noticesLimitReached) {
        count = `${noticesCount.successful_count}/${noticesCount.limit} Notices created`;
        text = "Please upgrade to create more notices.";
      } else if (transcriptsLimitReached) {
        text = "Please upgrade to request more transcripts.";
      }
    } else {
      if (transcriptsLimitReached && noticesLimitReached) {
        count = "Out of actions";
        text =
          "All free trial actions have been used. Please upgrade your account to create unlimited transcripts and notices.";
      } else if (noticesLimitReached) {
        count = `${noticesCount.successful_count}/${noticesCount.limit} Notices created`;
        text =
          "The maximum number of notices have been created.  Please upgrade to create more notices.";
      }
    }

    return {
      count,
      text,
    };
  }, [
    noticesCount.limit,
    noticesCount.successful_count,
    noticesLimitReached,
    transcriptsCount?.limit,
    transcriptsCount?.successful_count,
    transcriptsLimitReached,
  ]);

  return (
    <div {...scoped} className="upgrade-banner">
      <div className="cp-flex-center cp-ml-32">
        <CpIcon
          name="urgent-filled-large"
          fill="var(--cp-color-app-primary-dark-text)"
        />
        <div className="cp-ml-32 cp-subheader-sm">{bannerMsg.count}</div>
        <div className="cp-ml-32">{bannerMsg.text}</div>
      </div>
      <CpButton
        btnType="secondary"
        className="cp-ml-16 cp-mr-32"
        onClick={() => setShowUpgradeModal(true)}
        showLoader={showUpgradeModal}
      >
        Upgrade now
      </CpButton>
      <Suspense fallback={<div />}>
        <TNFreeTrialPaywallModal
          show={showUpgradeModal}
          close={() => setShowUpgradeModal(false)}
        />
      </Suspense>
    </div>
  );
}

const css = k`
  .upgrade-banner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 5.4rem;
    background-color: var(--cp-color-pill-text);
    color: var(--cp-color-app-primary-dark-text);
  }
`;
