import React, { useEffect, useState } from "react";
import { Scoped, k } from "kremling";
import { CpModal, CpButton, CpCard } from "canopy-styleguide!sofe";
import { get } from "lodash";

import CafForm from "src/client-transcripts/caf-form.component.js";
import { pullMethodKeys } from "./documents/documents.helper";

export default function UnfetchableDocs({ cafStatuses = [] }) {
  const [expandedIndex, setExpanded] = useState(null);
  const [groupedByFormType, setGroupedByFormType] = useState({});
  const [messageObj, setMessageObj] = useState({});

  useEffect(() => {
    setGroupedByFormType(
      cafStatuses.reduce((acc, result) => {
        return !result.caf_check
          ? {
              ...acc,
              [result.type]: acc[result.type]
                ? [...acc[result.type], result]
                : [result],
            }
          : acc;
      }, {})
    );
  }, [cafStatuses]);

  const getFormErrorMessage = () => {
    const { response = null } = messageObj;
    const errorMessage = get(response, "errors[0].errorMessage");

    if (!response?.message && !errorMessage) return;

    if (errorMessage) {
      return errorMessage;
    } else {
      return response.message;
    }
  };

  const formTypes = Object.keys(groupedByFormType);

  if (!formTypes.length) {
    return <div />;
  }

  return (
    <Scoped css={css}>
      <CpCard className="unfetchable-docs" data-testid="unfetchable-docs">
        <div className="title">
          Documents not provided by the IRS for this request
        </div>
        {formTypes.map((key, i) => {
          return (
            <div key={key} id="caf-form">
              <CafForm
                title={pullMethodKeys[key].title}
                list={groupedByFormType[key]}
                expanded={expandedIndex === i}
                setExpanded={setExpanded}
                index={i}
                showMessageDetail={setMessageObj}
              />
            </div>
          );
        })}
      </CpCard>
      <CpModal
        show={!!messageObj?.subtype && !!messageObj?.response?.message}
        onClose={() => setMessageObj({})}
      >
        <CpModal.Header title={messageObj?.subtype} />
        <CpModal.Body>
          <Scoped css={css}>
            <div className="error-message-title">IRS Error Message:</div>
            <div>{getFormErrorMessage()}</div>
            {messageObj.month && (
              <>
                <div className="cp-mt-16 error-message-title">
                  Months applicable:
                </div>
                <div>{messageObj.month}</div>
              </>
            )}
          </Scoped>
        </CpModal.Body>
        <CpModal.Footer>
          <CpButton btnType="primary" onClick={() => setMessageObj({})}>
            Done
          </CpButton>
        </CpModal.Footer>
      </CpModal>
    </Scoped>
  );
}

const css = k`
  .unfetchable-docs {
    max-height: 300px;
    max-width: 255px;
    overflow: auto;
    margin-top: 16px;
    padding: 8px 0;
  }

  .title {
    font-weight: bold;
    padding: 0 16px;
  }

  .error-message-title {
    font-size: 14px;
    color: var(--cp-color-app-inactive-text);
    font-style: italic;
    margin-bottom: 8px;
  }
`;
