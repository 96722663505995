import React, { useEffect, useState } from "react";
import { Scoped, k } from "kremling";

const empty = () => {};

export const Accordion = ({
  alignment = "right",
  open = false,
  className = "",
  onClick = empty,
  onOpenStart = empty,
  onOpen = empty,
  onCloseStart = empty,
  onClose = empty,
  toolbar,
  children,
}) => {
  const [currentlyAnimating, setCurrentlyAnimating] = useState(false);
  const [animationDisabled, setAnimationDisabled] = useState(true);

  useEffect(() => {
    setAnimationDisabled(false);
  }, []);

  const getHostClass = () => {
    const dynamicClasses = [];

    if (!currentlyAnimating && open) {
      dynamicClasses.push("accordion-opened");
    }
    if (!currentlyAnimating && !open) {
      dynamicClasses.push("accordion-closed");
    }
    if (currentlyAnimating && open) {
      dynamicClasses.push("accordion-opening");
    }
    if (currentlyAnimating && !open) {
      dynamicClasses.push("accordion-closing");
    }

    return dynamicClasses.join(" ").trim();
  };

  const getCollapseClass = () => {
    const dynamicClasses = [];

    dynamicClasses.push(open ? "open" : "closed");
    dynamicClasses.push(animationDisabled ? "no-transition" : "");

    return dynamicClasses.join(" ").trim();
  };

  const toolbarClasses = () => {
    return alignment === "right" ? "align-right" : "";
  };

  const animationStart = () => {
    if (open) {
      onOpenStart();
    } else {
      onCloseStart();
    }
    setCurrentlyAnimating(true);
  };

  const animationEnd = () => {
    if (open) {
      onOpen();
    } else {
      onClose();
    }
    setCurrentlyAnimating(false);
  };

  return (
    <Scoped css={css}>
      <div
        className={`accordion ${getHostClass()} ${className} cps-well__light`}
        onAnimationStart={animationStart}
        onAnimationEnd={animationEnd}
        tabIndex="-1"
      >
        <div
          className={`toolbar-wrapper ${toolbarClasses()}`}
          onClick={onClick}
        >
          <button className="accordion-trigger" />
          {toolbar}
        </div>

        <div className={`accordion-collapse ${getCollapseClass()}`}>
          <div className="accordion-content">{children}</div>
        </div>
      </div>
    </Scoped>
  );
};

const css = k`
  .accordion {
    display: block;
    background: transparent;
    color: var(--cp-color-app-primary-text);
    box-sizing: border-box;
    outline: none;
  }

  .accordion-opened {
    border: 2px solid var(--cp-color-app-primary);
  }

  .accordion-collapse {
    display: block;
    overflow: hidden;
    transition: all 400ms ease;
  }

  .no-transition {
    transition: all 0ms;
  }

  .open {
    height: auto;
  }

  .closed {
    height: 0;
    visibility: hidden;
  }

  .toolbar-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    padding: 0 24px;
  }

  .accordion-toolbar {
    order: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    white-space: nowrap;
    box-sizing: border-box;
    align-items: center;
    width: 100%;
    padding: 0 10px;
  }

  .accordion-content {
    padding: 0 32px 12px;
    outline: 0;
  }

  .accordion-trigger {
    height: 50px;
    width: 50px;
    order: 1;
    margin-left: auto;
    outline: none;
    background: transparent;
    border: none;
    padding: 0;
  }
`;
