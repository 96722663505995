import React from "react";
import { CpIcon } from "canopy-styleguide!sofe";
import { k, Scoped } from "kremling";

const css = k`
  .clickable-status {
      display: flex;
      align-items: center;
  }

  .clickable-status:hover {
      text-decoration: underline;
  }
`;

export const transcriptStatusTypes = {
  SUCCESS: "success",
  PARTIAL_SUCCESS: "partial_success",
  PENDING: "pending",
  ERROR: "error",
  RETRYING: "retrying",
};

export const translateTranscriptStatus = ({
  status,
  id,
  setStatusObj,
  category,
}) => {
  if (category === transcriptStatusTypes.PENDING) {
    return (
      <div className="cp-flex-center">
        <CpIcon className="cp-mr-4" name="misc-ellipsis" /> {status}
      </div>
    );
  } else if (category === transcriptStatusTypes.ERROR) {
    return (
      <Scoped css={css}>
        <div
          className="clickable-status"
          onClick={(e) => {
            e.preventDefault();
            setStatusObj({ id, status, category });
          }}
        >
          <CpIcon
            className="cp-mr-4"
            name="close-small"
            fill="var(--cp-color-app-warning-text)"
          />
          {status}
        </div>
      </Scoped>
    );
  } else if (category === transcriptStatusTypes.SUCCESS) {
    return (
      <div className="cp-flex-center">
        <CpIcon
          className="cp-mr-4"
          name="checkmark-small"
          fill="var(--cp-color-app-success-text)"
        />
        {status}
      </div>
    );
  } else if (category === transcriptStatusTypes.PARTIAL_SUCCESS) {
    return (
      <Scoped css={css}>
        <div
          className="clickable-status"
          onClick={(e) => {
            e.preventDefault();
            setStatusObj({ id, status, category });
          }}
        >
          <CpIcon
            className="cp-mr-4"
            name="urgent-filled-small"
            fill="var(--cp-color-app-icon)"
          />
          {status}
        </div>
      </Scoped>
    );
  } else if (category === transcriptStatusTypes.RETRYING) {
    return (
      <Scoped css={css}>
        <div
          className="clickable-status"
          onClick={(e) => {
            e.preventDefault();
            setStatusObj({ id, status, category });
          }}
        >
          <CpIcon
            className="cp-mr-4"
            name="af-retry-small"
            fill="var(--cp-color-app-icon)"
          />
          {status}
        </div>
      </Scoped>
    );
  } else {
    // if all else fails, but this code should never be reached
    return <div className="cp-ml-8">–</div>;
  }
};
