import { DateTime } from "luxon";

export const dateRangeTypes = {
  today: "today",
  yesterday: "yesterday",
  tomorrow: "tomorrow",
  currentWeek: "currentWeek",
  lastWeek: "lastWeek",
  currentMonth: "currentMonth",
  lastMonth: "lastMonth",
  currentQuarter: "currentQuarter",
  lastQuarter: "lastQuarter",
  yearToDate: "yearToDate",
  lastYear: "lastYear",
  pastDue: "pastDue",
  custom: "custom",
};

const dateRanges = {
  [dateRangeTypes.today]: {
    label: "Today",
    type: "today",
    filter: {
      startDate: DateTime.now().toLocaleString(DateTime.DATE_SHORT),
      endDate: DateTime.now().toLocaleString(DateTime.DATE_SHORT), // today
    },
  },
  [dateRangeTypes.yesterday]: {
    label: "Yesterday",
    type: "yesterday",
    filter: {
      startDate: DateTime.now()
        .minus({ days: 1 })
        .toLocaleString(DateTime.DATE_SHORT),
      endDate: DateTime.now()
        .minus({ days: 1 })
        .toLocaleString(DateTime.DATE_SHORT), // yesterday
    },
  },
  [dateRangeTypes.tomorrow]: {
    label: "Tomorrow",
    type: "tomorrow",
    filter: {
      startDate: DateTime.now()
        .plus({ days: 1 })
        .toLocaleString(DateTime.DATE_SHORT),
      endDate: DateTime.now()
        .plus({ days: 1 })
        .toLocaleString(DateTime.DATE_SHORT), // yesterday
    },
  },
  [dateRangeTypes.currentWeek]: {
    label: "This week",
    type: "currentWeek",
    filter: {
      startDate: DateTime.now()
        .startOf("week")
        .minus({ days: 1 })
        .toLocaleString(DateTime.DATE_SHORT), // sunday of current week
      endDate: DateTime.now()
        .endOf("week")
        .minus({ days: 1 })
        .toLocaleString(DateTime.DATE_SHORT), // saturday of current week
    },
  },
  [dateRangeTypes.lastWeek]: {
    label: "Last week",
    type: "lastWeek",
    filter: {
      startDate: DateTime.now()
        .minus({ weeks: 1 })
        .startOf("week")
        .minus({ days: 1 })
        .toLocaleString(DateTime.DATE_SHORT), // sunday of last week
      endDate: DateTime.now()
        .minus({ weeks: 1 })
        .endOf("week")
        .minus({ days: 1 })
        .toLocaleString(DateTime.DATE_SHORT), // saturday of last week
    },
  },
  [dateRangeTypes.currentMonth]: {
    label: "Current month",
    type: "currentMonth",
    filter: {
      startDate: DateTime.now()
        .startOf("month")
        .toLocaleString(DateTime.DATE_SHORT), // day 1 of this month
      endDate: DateTime.now()
        .endOf("month")
        .toLocaleString(DateTime.DATE_SHORT), //last day of the month
    },
  },
  [dateRangeTypes.lastMonth]: {
    label: "Last month",
    type: "lastMonth",
    filter: {
      startDate: DateTime.now()
        .minus({ months: 1 })
        .startOf("month")
        .toLocaleString(DateTime.DATE_SHORT), // day 1 of last month
      endDate: DateTime.now()
        .minus({ months: 1 })
        .endOf("month")
        .toLocaleString(DateTime.DATE_SHORT),
    },
  },
  [dateRangeTypes.currentQuarter]: {
    label: "Current quarter",
    type: "currentQuarter",
    filter: {
      startDate: DateTime.now()
        .startOf("quarter")
        .toLocaleString(DateTime.DATE_SHORT),
      endDate: DateTime.now()
        .endOf("quarter")
        .toLocaleString(DateTime.DATE_SHORT),
    },
  },
  [dateRangeTypes.lastQuarter]: {
    label: "Last quarter",
    type: "lastQuarter",
    filter: {
      startDate: DateTime.now()
        .minus({ quarters: 1 })
        .startOf("quarter")
        .toLocaleString(DateTime.DATE_SHORT),
      endDate: DateTime.now()
        .minus({ quarters: 1 })
        .endOf("quarter")
        .toLocaleString(DateTime.DATE_SHORT),
    },
  },
  [dateRangeTypes.yearToDate]: {
    label: "Year to date",
    type: "yearToDate",
    filter: {
      startDate: DateTime.now()
        .startOf("year")
        .toLocaleString(DateTime.DATE_SHORT),
      endDate: DateTime.now().toLocaleString(DateTime.DATE_SHORT),
    },
  },
  [dateRangeTypes.lastYear]: {
    label: "Last year",
    type: "lastYear",
    filter: {
      startDate: DateTime.now()
        .minus({ years: 1 })
        .endOf("year")
        .toLocaleString(DateTime.DATE_SHORT),
      endDate: DateTime.now()
        .minus({ years: 1 })
        .endOf("year")
        .toLocaleString(DateTime.DATE_SHORT),
    },
  },
  [dateRangeTypes.pastDue]: {
    label: "Past due",
    type: "pastDue",
    filter: {
      startDate: "",
      endDate: DateTime.now().toLocaleString(DateTime.DATE_SHORT), // yesterday
    },
  },
  // custom
  [dateRangeTypes.custom]: {
    label: "Custom",
    type: "custom",
    filter: {
      startDate: DateTime.now()
        .minus({ days: 1 })
        .toLocaleString(DateTime.DATE_SHORT),
      endDate: DateTime.now().toLocaleString(DateTime.DATE_SHORT),
    },
  },
};

export const defaultDateFilterRanges = (isScheduledList) => [
  dateRanges[dateRangeTypes.custom],
  ...(isScheduledList ? [dateRanges[dateRangeTypes.pastDue]] : []), // only on scheduled transcripts tab
  dateRanges[dateRangeTypes.today],
  ...(isScheduledList ? [dateRanges[dateRangeTypes.tomorrow]] : []), // only on scheduled transcripts tab
  dateRanges[dateRangeTypes.yesterday],
  dateRanges[dateRangeTypes.currentWeek],
  ...(!isScheduledList ? [dateRanges[dateRangeTypes.lastWeek]] : []), // only on all transcripts tab
  ...(!isScheduledList ? [dateRanges[dateRangeTypes.currentMonth]] : []), // only on all transcripts tab
  ...(!isScheduledList ? [dateRanges[dateRangeTypes.lastMonth]] : []), // only on all transcripts tab
];
