import React from "react";
import { useCss, k } from "kremling";
import {
  CpButton,
  CpEmptyState,
  CpCard,
  constants,
} from "canopy-styleguide!sofe";
import { useHasAccess } from "cp-client-auth!sofe";

export default function TranscriptsEmptyState({
  title = "",
  headText = "Not found",
  subheadText = "This document was not found",
  icon = "es_doc_delete",
  requestBtnDisabled = false,
  cta = "",
  showHideRequestModal,
  chatSupport,
}) {
  const hasTranscriptsPull = useHasAccess("transcripts_pull");

  return (
    <div
      className="cp-pt-32 cp-ml-24"
      style={{ width: "87.5rem" }}
      {...useCss(css)}
    >
      <CpCard>
        <CpCard.Header>
          <div className="empty-state-header-title">{title}</div>
          <span className="cp-flex-center">
            {hasTranscriptsPull && (
              <CpButton
                btnType="primary"
                disabled={requestBtnDisabled}
                onClick={() => showHideRequestModal(true)}
              >
                Request transcripts
              </CpButton>
            )}
          </span>
        </CpCard.Header>
        <CpCard.Body>
          {chatSupport ? (
            <div className="transcripts-chat-support">
              <div className="cps-wt-semibold">
                {title} report is not available
              </div>
              <div className="cp-color-app-secondary-text cp-mt-12 cp-mb-24">
                Please reach out to support to resolve this issue
              </div>
              <div>
                <CpButton btnType="primary" id="canopy-intercom-launcher">
                  Chat with Support
                </CpButton>
              </div>
              <div className="cp-color-app-secondary-text cp-mt-8">
                <em>or call at {constants.canopyPhone}</em>
              </div>
            </div>
          ) : (
            <CpEmptyState
              img={icon}
              text={headText}
              subText={subheadText}
              cta={cta}
            />
          )}
        </CpCard.Body>
      </CpCard>
    </div>
  );
}

const css = k`
  .empty-state-header-title {
    font-size: 20px;
    font-weight: 400;
  }

  .transcripts-chat-support {
    text-align: center;
    margin: 24px 0;
  }
`;
