import React from "react";
import { TopnavSecondary } from "primary-navbar!sofe";
import { CpIcon, CpHeader } from "canopy-styleguide!sofe";
import { Link } from "react-router-dom";

export const HelpCenterDashboard = () => {
  return (
    <TopnavSecondary>
      <CpHeader style={{ backgroundColor: "var(--cp-color-well-l1-bg)" }}>
        <div className="cp-flex-center cps-title">
          <Link
            to="/transcripts/list"
            style={{ color: "var(--cp-color-app-primary-text)" }}
          >
            Transcripts
          </Link>
          <CpIcon name="caret-large-right" />
          <div>Help Center</div>
        </div>
      </CpHeader>
    </TopnavSecondary>
  );
};
