import React from "react";
import { CpEmptyState } from "canopy-styleguide!sofe";
import { Scoped, k } from "kremling";
import sanitizeHtml from "sanitize-html";

const allowedTags = sanitizeHtml.defaults.allowedTags.concat("img");

export const DocumentYear = React.forwardRef((props, ref) => {
  const year = props.subDocs[0].year;

  function normalizeElementId(elementId) {
    return elementId.trim().replace(/\s/g, "_");
  }

  return (
    <Scoped css={css}>
      <div
        id={year}
        key={props.subDocs[0].year}
        ref={ref}
        className="cps-card-table cps-flexible-focus"
      >
        {props.subDocs.map((subDoc) => {
          const markup = sanitizeHtml(subDoc.content, {
            allowedTags: allowedTags,
            allowedAttributes: {
              ...sanitizeHtml.defaults.allowedAttributes,
              "*": [
                "class",
                "border",
                "height",
                "width",
                "align",
                "style",
                "nowrap",
                "valign",
                "colspan",
              ],
            },
            exclusiveFilter: (frame) => {
              return frame.tag === "a";
            },
            nonTextTags: [
              "style",
              "script",
              "textarea",
              "noscript",
              "title",
              "a",
            ],
          });

          return (
            <div
              key={subDoc.id}
              id={normalizeElementId(
                `subdoc-${props.subDocs[0].year}-${subDoc.subtype}`
              )}
              className="cps-card-table cps-flexible-focus cp-mb-32"
            >
              <div className="cps-card-table__headcontent cps-bg-white grey-border">
                <div className="cps-subheader-sm">{`Form ${subDoc.document_title}`}</div>
              </div>
              <section className="loader-wrapper-docs">
                <div className="backend-html-container cp-p-24">
                  {markup &&
                  (markup.includes("table") || markup.includes("TDS")) ? (
                    <div dangerouslySetInnerHTML={{ __html: markup }} />
                  ) : (
                    <CpEmptyState
                      img="es_transcripts_list"
                      text="No content for this document"
                    />
                  )}
                </div>
              </section>
            </div>
          );
        })}
      </div>
    </Scoped>
  );
});

const css = k`
  .grey-border {
    border: 1px solid var(--cp-color-well-border);
  }

  .loader-wrapper-docs {
    background-color: var(--cp-color-card-bg);
    border: 1px solid var(--cp-color-well-border);
    border-top: none;
  }

  .backend-html-container td {
    font-family: Courier, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: var(--cp-color-app-primary-text);
    background-color: var(--cp-color-table-bg);
    margin-left: 0in;
  }

  .l_title {
    font-family: Times New Roman, Courier, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    color: var(--cp-color-app-primary-text);
  }
  
  .l_solidblack {
    color: var(--cp-color-app-primary-text);
    background-color: var(--cp-color-nav-bg);
  }

  .backend-html-container {
    min-height: 500px;
  }

  .backend-html-container img {
    max-width: 670px;
  }

  .version-compare-original-highlight {
    background-color: rgba(51, 102, 187, 0.15);
    border: 2px solid #3366BB;
    border-left: 10 px solid #3366BB;
  }

  .version-compare-diff-highlight {
    background-color: rgba(44, 185, 199, 0.15);
    border: 2px solid #2CB9C7;
    border-left: 10 px solid #2CB9C7;
  }

  .version-compare-doc-original {
    border-left: 10px solid #3366BB;
  }

  .version-compare-doc-diff {
    border-left: 10px solid #2CB9C7;
  }
`;
