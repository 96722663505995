import React, { useMemo } from "react";
import { useWithUserAndTenant, hasAccess } from "cp-client-auth!sofe";
import { isEmpty, find } from "lodash";

import TranscriptsListHeader from "src/dashboard/transcripts-list-header.component.js";
import TranscriptsListRow from "src/dashboard/transcripts-list-row.component.js";
import TranscriptsListEmptyState from "src/dashboard/transcripts-list-empty-state.component.js";

export default function TranscriptsList({
  sortOptions,
  filterParams,
  updateFilterParams,
  items,
  selectedItems,
  deselectItem,
  selectItem,
  allSelected,
  selectAllItems,
  deselectAllItems,
  showSettingsModal,
  setStatusObj,
  setShowRequestDialog,
  transcriptsCount,
  activeOrgs,
  isCrmHeirarchy,
}) {
  const [user] = useWithUserAndTenant();
  const userHasTranscriptPullPerm = hasAccess(user)("transcripts_pull");
  const filterActive = useMemo(
    () => !!find(filterParams, (filterProp) => !isEmpty(filterProp)),
    [filterParams]
  );

  return items.length || filterActive ? (
    <>
      <TranscriptsListHeader
        updateFilterParams={updateFilterParams}
        filterParams={filterParams}
        sortOptions={sortOptions}
        selectAllItems={selectAllItems}
        deselectAllItems={deselectAllItems}
        allSelected={allSelected}
        userHasTranscriptPullPerm={userHasTranscriptPullPerm}
        isCrmHeirarchy={isCrmHeirarchy}
      />
      {!items.length && filterActive ? (
        <TranscriptsListEmptyState
          emptyWithFilter
          userHasTranscriptPullPerm={userHasTranscriptPullPerm}
        />
      ) : (
        <div className="cp-mb-24">
          {items.map((item) => (
            <TranscriptsListRow
              key={item.transcript_id}
              showSettingsModal={showSettingsModal}
              item={item}
              selected={selectedItems.includes(item.transcript_id)}
              selectItem={selectItem}
              deselectItem={deselectItem}
              userHasTranscriptPullPerm={userHasTranscriptPullPerm}
              setStatusObj={setStatusObj}
              setShowRequestDialog={setShowRequestDialog}
              transcriptsCount={transcriptsCount}
              activeOrgs={activeOrgs}
              isCrmHeirarchy={isCrmHeirarchy}
            />
          ))}
        </div>
      )}
    </>
  ) : (
    <TranscriptsListEmptyState
      userHasTranscriptPullPerm={userHasTranscriptPullPerm}
    />
  );
}
