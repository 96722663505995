import React, { useMemo } from "react";
import { CpLoader, CpIcon } from "canopy-styleguide!sofe";
import { useCss, k } from "kremling";
import { useWithUserAndTenant, hasAccess } from "cp-client-auth!sofe";
import { isEmpty, find } from "lodash";

import TranscriptsListHeader from "src/dashboard/transcripts-list-header.component.js";
import TranscriptsListRow from "src/dashboard/transcripts-list-row.component.js";
import TranscriptsListEmptyState from "src/dashboard/transcripts-list-empty-state.component.js";

export default function TranscriptsScheduledList({
  activeOrgs,
  allSelected,
  deselectItem,
  deselectAllItems,
  filterParams,
  pastDueCount,
  scheduledItems,
  scheduleLoading,
  selectAllItems,
  selectItem,
  selectedItems,
  setShowRequestDialog,
  showSettingsModal,
  sortOptions,
  transcriptsCount,
  updateFilterParams,
  isCrmHeirarchy,
}) {
  const scoped = useCss(css);
  const [user] = useWithUserAndTenant();
  const userHasTranscriptPullPerm = hasAccess(user)("transcripts_pull");
  const filterActive = useMemo(
    () => !!find(filterParams, (filterProp) => !isEmpty(filterProp)),
    [filterParams]
  );

  return scheduleLoading ? (
    <CpLoader />
  ) : scheduledItems?.schedules?.length ? (
    <>
      {pastDueCount > 0 && (
        <div className="cp-pt-16">
          <div {...scoped} className="pending-banner">
            <div className="cp-flex-center cp-ml-32">
              <CpIcon name="misc-calendar" fill="var(--cp-color-app-icon)" />
              <div className="cp-ml-32">
                You have scheduled requests that require action. Please sign in
                to the IRS with each required organization.
              </div>
            </div>
          </div>
        </div>
      )}
      <TranscriptsListHeader
        isScheduledList
        updateFilterParams={updateFilterParams}
        filterParams={filterParams}
        sortOptions={sortOptions}
        selectAllItems={selectAllItems}
        deselectAllItems={deselectAllItems}
        allSelected={allSelected}
        userHasTranscriptPullPerm={userHasTranscriptPullPerm}
        isCrmHeirarchy={isCrmHeirarchy}
      />
      <div className="cp-mb-16">
        {scheduledItems.schedules.map((item) => (
          <TranscriptsListRow
            key={item.client_id}
            isScheduledList
            showSettingsModal={showSettingsModal}
            item={item}
            selected={selectedItems.includes(item.transcript_id)}
            selectItem={selectItem}
            deselectItem={deselectItem}
            userHasTranscriptPullPerm={userHasTranscriptPullPerm}
            setShowRequestDialog={setShowRequestDialog}
            transcriptsCount={transcriptsCount}
            activeOrgs={activeOrgs}
            isCrmHeirarchy={isCrmHeirarchy}
          />
        ))}
      </div>
    </>
  ) : (
    <>
      {filterActive && (
        <TranscriptsListHeader
          isScheduledList
          updateFilterParams={updateFilterParams}
          filterParams={filterParams}
          sortOptions={sortOptions}
          selectAllItems={selectAllItems}
          deselectAllItems={deselectAllItems}
          allSelected={allSelected}
          userHasTranscriptPullPerm={userHasTranscriptPullPerm}
          isCrmHeirarchy={isCrmHeirarchy}
        />
      )}
      <TranscriptsListEmptyState emptyWithFilter={filterActive} isSchedule />
    </>
  );
}

const css = k`
  .pending-banner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 78rem;
    height: 5.2rem;
    background-color: var(--cp-color-card-bg);
    border-radius: 4px;
    border: 1px solid var(--cp-color-card-border);
    border-left: 0.8rem solid var(--cp-color-input-active-border);
    box-shadow: var(--cp-box-shadow-l2);
    position: sticky;
    margin-bottom: 1.6rem;
    margin-left: 1.6rem;
  }
`;
