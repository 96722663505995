import React, { useCallback } from "react";
import { k, useCss } from "kremling";
import sanitizeHtml from "sanitize-html";

export const ReportsTable = ({ reportTableHtml }) => {
  const scoped = useCss(css);

  const getHTMLReport = useCallback(() => {
    const markup = sanitizeHtml(reportTableHtml, {
      allowedTags: sanitizeHtml.defaults.allowedTags,
      allowedAttributes: {
        ...sanitizeHtml.defaults.allowedAttributes,
        "*": [
          "class",
          "border",
          "height",
          "width",
          "align",
          "style",
          "nowrap",
          "valign",
          "colspan",
          "rowspan",
        ],
      },
      nonTextTags: ["style", "script", "textarea", "noscript", "title"],
    });

    return (
      <div className="scrollable-table" {...scoped}>
        <div className="message" dangerouslySetInnerHTML={{ __html: markup }} />
      </div>
    );
  }, [scoped, reportTableHtml]);

  return getHTMLReport();
};

const css = k`
  .report-table-content thead {
    color: var(--cp-color-app-primary-text);
    font-weight: normal !important;
    font-size: 1.2rem;
  }

  .report-table-content tbody tr {
    height: auto !important;
    padding: 0;
    width: 100%;
  }
  
  .report-table-content {
    border-collapse: collapse;
    width: 100%;
  }

  .report-table-content thead tr {
    height: 45px;
    padding: 0;
    width: 100%;
  }

  .scrollable-table {
    padding: 0;
    overflow: auto;
  }

  .report-table-header {
    color: var(--cp-color-app-secondary-text);
    position: relative;
    white-space: nowrap;
    padding-left: 1.6rem !important;
    padding-right: 1.6rem !important;
    border: 1px solid var(--cp-color-table-border);
    text-align: left;
  }

  .report-table-header[colspan]:not([colspan="1"]) {
    text-align: center;
  }

  .sticky {
    position: sticky;
    left: 0;
    background-color: var(--cp-color-table-bg);
    z-index: 1;
    border-left: none !important;
    background-clip: padding-box;
  }

  .report-table-data {
    height: auto!important;
    padding: 10px 25px 10px 16px !important;
    border-bottom: 0.1rem solid var(--cp-color-table-border);
    border: 1px solid var(--cp-color-table-border);
    text-align: left;
  }

  .report-table-data[colspan]:not([colspan="1"]) {
    text-align: center;
  }

  .sticky-header-cols:nth-child(1){
    position: sticky;
    left: 0;
    background-color: var(--cp-color-table-bg);
    z-index: 1;
    color: var(--cp-color-app-secondary-text);
    height: 45px!important;
    border-left: none !important;
  }

  .sticky-data-cols:nth-child(1) {
    position: sticky;
    left: 0;
    background-color: var(--cp-color-table-bg);
    z-index: 1;
    border-left: none !important;
  }
  
  .row-data-dash {
    font-weight: bold;
    color: var(--cp-color-app-secondary-text);
    padding-left: 5px;
  }

  .reports-checkmark {
    fill: var(--cp-color-button-flat-text);
  }

  .trailing-asterisk::after {
    content: '*';
  }
`;
