import { DateTime } from "luxon";
import { userHasOnlyLicense } from "cp-client-auth!sofe";

export const fromJSDateToISO = (date) => {
  return DateTime.fromJSDate(date).startOf("day").toISO();
};

export const getISODateNow = () => {
  return DateTime.now().startOf("day").toISO();
};

export const fromISOtoShortDate = (date) => {
  return DateTime.fromISO(date).toLocaleString(DateTime.DATE_SHORT);
};

export const isExpiredDate = (date) => {
  return date < getISODateNow();
};

const normalizeDateMonth = (month) => {
  // if the month is above 12 (Dec) then we need to subtract 12 to get correct month number
  // Example: 16 - 12 = 4 (April)
  if (month > 12) {
    return month - 12;
  }

  return month;
};

export const createCronString = (date, cadence) => {
  // CRON EXAMPLES
  // cron schedule expression = "minute hour day(month) month day(week)"
  // start date = 2021-08-18T06:00:00+00:00
  // monthly on the 18th at 10am utc = "0 10 18 * *"
  // weekly every wednesday at 10am utc  = "0 10 * * 3"
  // annually at 10am utc  = "0 10 18 08 *"
  // quarterly on aug, nov, feb, may at 10am utc  = "0 10 18 8,11,2,5 *"
  if (!date) return;

  const { year, month, day } = DateTime.fromISO(date).toObject();

  if (cadence === cadences.NO_REPEAT) {
    return "";
  } else if (cadence === cadences.WEEKLY) {
    // Weekdays are 1-7 Mon - Sun
    let weekday = DateTime.local(year, month, day).weekday;

    return `0 10 * * ${weekday}`;
  } else if (cadence === cadences.MONTHLY) {
    return `0 10 ${day} * *`;
  } else if (cadence === cadences.QUARTERLY) {
    // get the month of each quarter in put in a comma separated string for cron Ex. 8,11,2,5
    const q2 = normalizeDateMonth(month + 3);
    const q3 = normalizeDateMonth(month + 6);
    const q4 = normalizeDateMonth(month + 9);
    const quarterlyMonths = [month, q2, q3, q4].join();
    return `0 10 ${day} ${quarterlyMonths} *`;
  } else if (cadence === cadences.ANNUALLY) {
    return `0 10 ${day} ${month} *`;
  }
};

export const cadences = {
  NO_REPEAT: "none",
  WEEKLY: "weekly",
  MONTHLY: "monthly",
  QUARTERLY: "quarterly",
  ANNUALLY: "annually",
};

export const scheduleCadenceOptions = [
  {
    id: cadences.NO_REPEAT,
    name: "Does not repeat",
  },
  {
    id: cadences.WEEKLY,
    name: "Every week",
  },
  {
    id: cadences.MONTHLY,
    name: "Every month",
  },
  {
    id: cadences.QUARTERLY,
    name: "Every quarter",
  },
  {
    id: cadences.ANNUALLY,
    name: "Every year",
  },
];

export const defaultSettings = {
  starting_at: getISODateNow(),
  ending_at: "",
  cron: null,
  cadence: null,
  poa_expiration_at: null,
  forms: null,
  error_count: 0,
  caf_id: null,
  is_calendar: true,
};

export const compareDates = (start, end, cadence, settingsOnly) => {
  if (start && end && cadence) {
    let startDate = DateTime.fromISO(start);
    let endDate = DateTime.fromISO(end);
    let format =
      cadence === "weekly"
        ? "weeks"
        : cadence === "monthly" || cadence === "quarterly"
        ? "months"
        : "years";
    let difference = endDate.diff(startDate, format).toObject();
    if (
      (difference[format] < 1 ||
        (cadence === "quarterly" && difference[format] < 3)) &&
      start === getISODateNow() &&
      settingsOnly
    ) {
      return "The end date is before the second scheduled request. These transcripts will never be requested.";
    } else if (
      difference[format] < 1 ||
      (cadence === "quarterly" && difference[format] < 3)
    ) {
      return "The end date is before the second scheduled request. These transcripts will only be requested once.";
    }
  }
  return;
};

export const formatCafNumber = (cafNumber = "") => {
  if (cafNumber?.length > 9) {
    // format as 1111-11111-R
    return cafNumber.replace(/(\d{4})(\d{5})*/, "$1-$2-");
  }

  // format as 1111-11111
  return cafNumber.replace(/(\d{4})(\d{5})/, "$1-$2");
};

export function isTranscriptsOnly(user) {
  return userHasOnlyLicense(user, "transcripts");
}
