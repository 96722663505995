import React from "react";
import { NavContent, TopnavSecondary } from "primary-navbar!sofe";
import { Scoped, k } from "kremling";
import { CpLoader, CpHeader } from "canopy-styleguide!sofe";
import Cancelable from "react-disposable-decorator";
import queryString from "query-string";
import { handleError } from "src/error";
import { warningToast } from "toast-service!sofe";

import { sendIrsAuthCode } from "../resources/transcripts.resource";

@Cancelable
export default class IRSAuthorized extends React.Component {
  componentDidMount() {
    const code = queryString.parse(this.props.location.search).code;

    if (code) {
      const isTest =
        document.referrer?.includes("api.alt") ||
        document.referrer?.includes("TEST");
      this.sendAuthCode(code, !isTest);
    } else {
      warningToast(
        "We were unable to connect to the IRS because the IRS responded without an authentication code."
      );
    }
  }

  sendAuthCode = (code, isProd) => {
    this.props.cancelWhenUnmounted(
      sendIrsAuthCode(code, isProd).subscribe(
        () => window.self.close(),
        (err) => {
          handleError(err);
          window.self.close();
        }
      )
    );
  };

  render() {
    return (
      <NavContent
        hasTopnavSecondary={true}
        style={{ marginLeft: 80, padding: "0px" }}
      >
        <TopnavSecondary>
          <CpHeader style={{ backgroundColor: "var(--cp-color-well-l1-bg)" }}>
            <div className="cps-subheader">Transcripts</div>
          </CpHeader>
        </TopnavSecondary>
        <Scoped css={css}>
          <div className="irs-auth-loader">
            <CpLoader size="lg" />
            <div className="auth-loader-text">Authorizing IRS login...</div>
          </div>
        </Scoped>
      </NavContent>
    );
  }
}

const css = k`
  .irs-auth-loader {
    margin-top: 16rem;
  }

  .auth-loader-text {
    width: 100%;
    justify-content: center;
    display: flex;
  }
`;
