import React, { useCallback, useEffect, useState } from "react";
import {
  CpOverlay,
  CpButton,
  CpCheckbox,
  CpIcon,
  CpTooltip,
  CpToggle,
} from "canopy-styleguide!sofe";
import { useCss, k } from "kremling";
import { handleError } from "src/error";
import { cloneDeep, isEmpty, clone, startCase } from "lodash";

import { getForms } from "../../resources/transcripts.resource";
import { notifyAnalytics } from "src/resources/analytics.resource";

export default function ManageFormsSelection({
  saveSettings,
  transcriptSettings,
  isBusiness = false,
  newClient = false,
  requestModal = false,
}) {
  const scoped = useCss(css);
  const [allProductOptions, setAllProductOptions] = useState({});
  const [activeProduct, setActiveProduct] = useState("");
  const [changesMade, setChangesMade] = useState(false);
  const [formOptions, setFormOptions] = useState({});
  const [newFormSettings, setNewFormSettings] = useState({});
  const [productNavItems, setProductNavItems] = useState([]);
  const [showFormsOverlay, setShowFormsOverlay] = useState(false);
  const [expandedForms, setExpandedForms] = useState([]);

  useEffect(() => {
    // gets a list of all the forms and years available for each product. Example product would be "Account transcript"
    const subscription = getForms(
      isBusiness,
      transcriptSettings.is_calendar
    ).subscribe((res) => {
      const fedTaxProducts = res?.["Federal Tax"] || {};
      setAllProductOptions(fedTaxProducts);
      const fedTaxProductKeys = Object.keys(fedTaxProducts || {});
      setProductNavItems(fedTaxProductKeys);
      setActiveProduct(fedTaxProductKeys[0]);
      if (newClient) {
        setNewFormSettings(fedTaxProducts);
        saveSettings({
          ...transcriptSettings,
          forms: { "Federal Tax": fedTaxProducts },
        });
      }
    }, handleError);

    return () => subscription.unsubscribe?.();
  }, [isBusiness, transcriptSettings.is_calendar]);

  useEffect(() => {
    const formSettingsCloned = cloneDeep(
      transcriptSettings?.forms?.["Federal Tax"]
    );

    if (formSettingsCloned) {
      // set to what was returned from settings
      setNewFormSettings(formSettingsCloned);
    } else if (!isEmpty(allProductOptions)) {
      // if no form settings, set to default
      setNewFormSettings(allProductOptions);
      saveSettings({
        ...transcriptSettings,
        forms: { "Federal Tax": allProductOptions },
      });
    }
  }, [transcriptSettings?.forms, allProductOptions, saveSettings]);

  useEffect(() => {
    if (activeProduct) {
      setFormOptions(allProductOptions[activeProduct]);
    }
  }, [activeProduct, allProductOptions]);

  const deselectAllYearsForForm = useCallback(
    (form) => {
      setChangesMade(true);
      setNewFormSettings({
        ...newFormSettings,
        [activeProduct]: {
          ...newFormSettings[activeProduct],
          [form]: {},
        },
      });
    },
    [activeProduct, newFormSettings]
  );

  const expandFormSection = useCallback(
    (checked, form) => {
      if (checked) {
        setExpandedForms([...expandedForms, `${activeProduct}-${form}`]);
      } else {
        const clonedToggledList = clone(expandedForms);
        const formIndex = clonedToggledList.findIndex(
          (item) => `${activeProduct}-${form}` === item
        );
        clonedToggledList.splice(formIndex, 1);
        setExpandedForms(clonedToggledList);
      }
    },
    [activeProduct, expandedForms]
  );

  const getFormCount = useCallback(
    (product) => {
      return Object.keys(newFormSettings?.[product] || {}).length;
    },
    [newFormSettings]
  );

  const getFormYearsCount = useCallback(
    (formKey) => {
      return Object.keys(newFormSettings?.[activeProduct]?.[formKey] || {})
        .length;
    },
    [activeProduct, newFormSettings]
  );

  const getFormLabel = (formName) => {
    if (formName.replace(/[^a-zA-Z]+/g, "")?.length > 3) {
      return startCase(formName.toLowerCase());
    } else {
      return formName;
    }
  };

  const getSortedFormsArray = useCallback(() => {
    if (isEmpty(formOptions)) return [];
    const commonForms = [
      "1065",
      "1120",
      "1040",
      "1040 SEPARATE ASSESSMENT",
      "CIVIL PENALTY",
    ];
    const formOptionKeys = Object.keys(formOptions);

    // For business, add secondary 900 forms
    let secondaryForms = [];
    if (isBusiness) {
      secondaryForms = formOptionKeys
        .filter((key) => key.startsWith("9"))
        .sort();
    }

    // filter out forms that are not in data set
    const popularForms = [...commonForms, ...secondaryForms].filter(
      (key) => !!formOptionKeys.includes(key)
    );

    // Filter out the popular forms from data set
    const otherForms = formOptionKeys
      .filter((key) => !popularForms.includes(key))
      .sort();

    return [...popularForms, ...otherForms];
  }, [formOptions, isBusiness]);

  const onFormYearChange = useCallback(
    ({ isSelected, formKey, year }) => {
      setChangesMade(true);
      if (isSelected) {
        setNewFormSettings({
          ...newFormSettings,
          [activeProduct]: {
            ...newFormSettings[activeProduct],
            [formKey]: {
              ...newFormSettings[activeProduct][formKey],
              [year]: formOptions[formKey][year], // set year to the default array of months from formOptions
            },
          },
        });
      } else {
        const clonedProduct = cloneDeep(newFormSettings[activeProduct]);
        delete clonedProduct[formKey][year];
        setNewFormSettings({
          ...newFormSettings,
          [activeProduct]: clonedProduct,
        });
      }
    },
    [activeProduct, formOptions, newFormSettings]
  );

  const saveFormsChanges = useCallback(() => {
    setShowFormsOverlay(false);
    setChangesMade(false);
    notifyAnalytics("transcripts.manage_forms_selection", {
      client_type: isBusiness ? "business" : "individual",
      request_modal: requestModal,
    });
    return saveSettings({
      ...transcriptSettings,
      forms: { "Federal Tax": newFormSettings },
    });
  }, [
    isBusiness,
    newFormSettings,
    requestModal,
    saveSettings,
    transcriptSettings,
  ]);

  const selectAllYearsForForm = useCallback(
    (form) => {
      setChangesMade(true);
      setNewFormSettings({
        ...newFormSettings,
        [activeProduct]: {
          ...newFormSettings[activeProduct],
          [form]: formOptions[form],
        },
      });
    },
    [activeProduct, formOptions, newFormSettings]
  );

  const selectFormChange = useCallback(
    (selected, form) => {
      setChangesMade(true);
      if (selected) {
        // add the form to the product form list and set that form years array to what form options are for that form
        setNewFormSettings({
          ...newFormSettings,
          [activeProduct]: {
            ...newFormSettings[activeProduct],
            [form]: formOptions[form],
          },
        });
      } else {
        // remove the form from the product form list
        const clonedProduct = cloneDeep(newFormSettings[activeProduct]);
        delete clonedProduct[form];
        setNewFormSettings({
          ...newFormSettings,
          [activeProduct]: clonedProduct,
        });
      }
    },
    [activeProduct, formOptions, newFormSettings]
  );

  return (
    <div>
      <div {...scoped} className="manage-forms-btn">
        <em style={{ width: "29.5rem" }}>
          Please select the forms you wish to request
          <CpTooltip text="Selecting only the forms and periods for which you have authorization helps reduce IRS errors.">
            <CpIcon name="information-circle-open-small" />
          </CpTooltip>
        </em>
        <CpButton
          btnType="flat"
          onClick={() => {
            notifyAnalytics("transcripts.manage_forms_click", {
              client_type: isBusiness ? "business" : "individual",
              request_modal: requestModal,
            });
            setShowFormsOverlay(true);
          }}
        >
          Manage forms
        </CpButton>
      </div>
      {!isEmpty(allProductOptions) && (
        <CpOverlay
          show={showFormsOverlay}
          onClose={() => {
            setNewFormSettings(
              cloneDeep(transcriptSettings?.forms?.["Federal Tax"])
            );
            setShowFormsOverlay(false);
            setChangesMade(false);
          }}
        >
          <CpOverlay.Header title="Manage Forms">
            <CpButton
              btnType="primary"
              disabled={!changesMade}
              onClick={saveFormsChanges}
            >
              Save changes
            </CpButton>
          </CpOverlay.Header>
          <CpOverlay.Body>
            <div {...scoped} className="cp-flex">
              <div className="products-section">
                {productNavItems.map((product) => (
                  <CpButton
                    btnType={product === activeProduct ? "flat" : "tertiary"}
                    block
                    key={product}
                    className="product-item"
                    onClick={() => setActiveProduct(product)}
                  >
                    {product}{" "}
                    <em style={{ fontWeight: "400" }}>
                      ({getFormCount(product)} selected)
                    </em>
                  </CpButton>
                ))}
              </div>
              <div className="forms-list">
                <div className="product-title">{activeProduct}</div>
                {getSortedFormsArray().map((formKey) => {
                  const selected =
                    !!newFormSettings?.[activeProduct]?.[formKey];
                  const expanded = expandedForms.includes(
                    `${activeProduct}-${formKey}`
                  );
                  const allYearsSelected =
                    Object.keys(
                      newFormSettings?.[activeProduct]?.[formKey] || {}
                    )?.length ===
                    Object.keys(formOptions?.[formKey] || {})?.length;
                  return (
                    <div className="form-section" key={activeProduct + formKey}>
                      <div className="form-header">
                        <div>
                          <CpToggle
                            checked={selected}
                            onChange={(checked) => {
                              expandFormSection(checked, formKey);
                              selectFormChange(checked, formKey);
                            }}
                          />
                          <span className="cp-ml-24">
                            {getFormLabel(formKey)}
                          </span>
                          {selected && (
                            <em
                              style={{
                                marginLeft: "0.8rem",
                                fontWeight: "400",
                                fontSize: "1.3rem",
                              }}
                            >
                              ({getFormYearsCount(formKey)} years selected)
                            </em>
                          )}
                        </div>
                        {selected && (
                          <CpButton
                            onClick={() =>
                              expandFormSection(!expanded, formKey)
                            }
                            icon={
                              expanded ? "caret-large-up" : "caret-large-down"
                            }
                            aria-label="Expand form section"
                          />
                        )}
                      </div>
                      {expanded && (
                        <>
                          <div className="cp-ph-12 cp-mb-12">
                            <hr className="cp-mv-0" />
                          </div>
                          <div className="years-subheader">
                            <div>
                              <strong className="cp-mr-12">Select Years</strong>
                              <em className="cp-color-app-secondary-text">
                                Increase your success rate by selecting only the
                                necessary tax years for this form
                              </em>
                            </div>
                            <CpButton
                              btnType="flat"
                              onClick={() => {
                                if (allYearsSelected) {
                                  deselectAllYearsForForm(formKey);
                                } else {
                                  selectAllYearsForForm(formKey);
                                }
                              }}
                            >
                              {allYearsSelected ? "Deselect all" : "Select all"}
                            </CpButton>
                          </div>
                          <div className="years-section">
                            {Object.keys(formOptions?.[formKey] || {})
                              .sort()
                              .reverse()
                              .map((year) => (
                                <CpCheckbox
                                  key={formKey + year}
                                  className="cp-mb-8 cp-pt-0"
                                  onChange={(isSelected) =>
                                    onFormYearChange({
                                      isSelected,
                                      formKey,
                                      year,
                                    })
                                  }
                                  checked={
                                    !!newFormSettings?.[activeProduct]?.[
                                      formKey
                                    ]?.[year]
                                  }
                                >
                                  {year}
                                </CpCheckbox>
                              ))}
                          </div>
                        </>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          </CpOverlay.Body>
        </CpOverlay>
      )}
    </div>
  );
}

const css = k`
  .manage-forms-btn {
    display: flex;
    border: 0.1rem solid var(--cp-color-well-border);
    border-radius: 4px;
    background: var(--cp-color-well-l2-bg);
    color: var(--cp-color-app-secondary-text);
    padding: 1.2rem;
    margin: 1.6rem 0;
    align-items: center;
    justify-content: space-between;
  }

  .products-section {
    width: 300px;
    margin-left: 16px;
    margin-top: 8px;
    border: 1px solid var(--cp-color-app-border);
    border-radius: 8px;
    padding: 16px 0;
    height: fit-content;
  }

  .product-item, .product-item > .cp-button__content {
    font-weight: 600;
    justify-content: start;
    border-radius: 0;
    height: 40px;
  }

  .forms-list {
    width: 90rem;
    border: 1px solid var(--cp-color-card-border);
    border-radius: 0.8rem;
    margin-left: 3.2rem;
    margin-top: 0.8rem;
    padding: 1.2rem 0.8rem;
    overflow: auto;
  }

  .product-title {
    font-size: 1.8rem;
    font-weight: 500;
    margin: 2.4rem 0 2.4rem 4rem
  }


  .form-section {
    margin: 0.4rem 0 1.2rem 4rem;
    border: 1px solid var(--cp-color-card-border);;
    border-radius: 0.4rem;
    background-color: var(--cp-color-well-l2-bg);
  }

  .form-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1.8rem;
    font-weight: 500;
    height: 5rem;
    margin-left: 2.4rem;
    margin-right: 2.4rem;
  }

  .years-subheader {
    margin-left: 2.4rem;
    margin-right: 2.4rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .years-section {
    margin: 1.6rem 0 1.6rem 4.8rem;
    height: 12rem;
    display: flex;
    flex-flow: column wrap;
    gap: 0 7.8rem;
    max-width: fit-content;
  }
`;
